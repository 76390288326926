import { Percent } from '@uniswap/sdk-core'
import { IHasTradeProperties } from 'state/routing/types'

import { worstExecutionPrice } from './calculateSlippageAmount'

/**
 * Returns true if the trade requires a confirmation of details before we can submit it
 * @param args either a pair of V2 trades or a pair of V3 trades
 */
export function tradeMeaningfullyDiffers(
  tradeA: IHasTradeProperties,
  tradeB: IHasTradeProperties,
  slippage: Percent
): boolean {
  return (
    tradeA.tradeType !== tradeB.tradeType ||
    !tradeA.inputAmount.currency.equals(tradeB.inputAmount.currency) ||
    !tradeA.outputAmount.currency.equals(tradeB.outputAmount.currency) ||
    tradeB.executionPrice.lessThan(worstExecutionPrice(tradeB, slippage))
  )
}
