import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount, Token } from '@uniswap/sdk-core'
import { FarmAction } from 'components/FarmCard'
import { Text } from 'rebass'

import { ButtonPrimary } from '../../../components/Button'
import CurrencyLogo from '../../../components/Logo/CurrencyLogo'
import { RowBetween, RowFixed } from '../../../components/Row'
import { ThemedText } from '../../../theme'

function actionRowText(farmAction?: FarmAction) {
  if (farmAction === FarmAction.Add) return 'Depositing'
  if (farmAction === FarmAction.Remove) return 'Withdrawing'
  if (farmAction === FarmAction.Claim) return 'Claiming Rewards'
  return ''
}

function statusMessage(farmAction?: FarmAction) {
  if (farmAction === FarmAction.Add) return 'Confirm Deposit To Farm'
  if (farmAction === FarmAction.Remove) return 'Confirm Withdraw from Farm'
  if (farmAction === FarmAction.Claim) return 'Confirm claiming rewards Farm'
  return ''
}

export function ConfirmAddFarmModalBottom({
  lpToken,
  formattedTokenName,
  amountDelta: amountDelta,
  depositedAmount,
  onExecute,
  farmAction,
}: {
  lpToken: Currency
  formattedTokenName: string
  amountDelta?: CurrencyAmount<Token>
  depositedAmount?: CurrencyAmount<Token>
  onExecute: () => void
  farmAction?: FarmAction
}) {
  return (
    <>
      <RowBetween>
        <ThemedText.DeprecatedBody>
          <Trans>{formattedTokenName} Deposited</Trans>
        </ThemedText.DeprecatedBody>
        <RowFixed>
          <CurrencyLogo currency={lpToken} style={{ marginRight: '8px' }} />
          <ThemedText.DeprecatedBody>{depositedAmount?.toSignificant(6)}</ThemedText.DeprecatedBody>
        </RowFixed>
      </RowBetween>
      {farmAction !== FarmAction.Claim && (
        <RowBetween>
          <ThemedText.DeprecatedBody>
            <Trans>
              {formattedTokenName} {actionRowText(farmAction)}
            </Trans>
          </ThemedText.DeprecatedBody>
          <RowFixed>
            <CurrencyLogo currency={lpToken} style={{ marginRight: '8px' }} />
            <ThemedText.DeprecatedBody>{amountDelta?.toSignificant(6)}</ThemedText.DeprecatedBody>
          </RowFixed>
        </RowBetween>
      )}
      <ButtonPrimary style={{ margin: '20px 0 0 0' }} onClick={onExecute}>
        <Text fontWeight={535} fontSize={20}>
          <Trans>{statusMessage(farmAction)}</Trans>
        </Text>
      </ButtonPrimary>
    </>
  )
}
