import { createSlice } from '@reduxjs/toolkit'
import FarmListJSON from 'constants/farms/default_spooky_farms.json'

import { FarmsState } from './types'

const initialState: FarmsState = { data: FarmListJSON.farms }

const getDefaultFarmsIndices = () => {
  const indices: any = {}
  FarmListJSON.farms.forEach(({ pid, version }, index) => {
    if (indices[version] == null) indices[version] = {}
    indices[version][pid] = index
  })
  return indices
}

const farmsSlice = createSlice({
  name: 'Farms',
  initialState,
  reducers: {
    setFarmsConfigs: (state, action) => {
      const configs = action.payload
      const indices = getDefaultFarmsIndices()

      state.fetchedConfigs = configs
      state.data = configs.map((config: any) => {
        if (indices[config.version] != null && indices[config.version][config.pid] != null) {
          return {
            ...state.data[indices[config.version][config.pid]],
            ...config,
          }
        }
        return config
      })
    },
    setFarmsPublicData: (state, action) => {
      const farmsData: any = action.payload

      // Escape if arrays have different length
      if (farmsData.length !== state.data.length) return

      farmsData.forEach((farmData: any, index: number) => {
        state.data[index] = { ...state.data[index], ...farmData }
      })
    },
    setFarmsUserData: (state, action) => {
      const farmsUserData = action.payload

      // Escape if arrays have different length
      if (farmsUserData.length !== state.data.length) return

      farmsUserData.forEach((userData: any, index: number) => {
        state.data[index] = { ...state.data[index], userData }
      })
    },
  },
})

const { setFarmsConfigs, setFarmsPublicData, setFarmsUserData } = farmsSlice.actions

// const fetchFarmUserDataAsync = (chainId: number, account: string) => async (dispatch: any, getState: any) => {
//   const configs = getState().farms.fetchedConfigs || defaultFarms
//   const farmsUserData = await fetchFarmUser(chainId, configs, account)
//   dispatch(setFarmsUserData(farmsUserData))
// }

// const fetchFarmsPublicDataAsync = (chainId: number) => async (dispatch: any, getState: any) => {
//   const configs = getState().farms.fetchedConfigs || defaultFarms
//   const farms = await fetchFarms(chainId, configs)
//   dispatch(setFarmsPublicData(farms))
// }

export default farmsSlice.reducer
