import { Trans } from '@lingui/macro'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { RowBetween } from 'components/Row'
import { Label } from 'components/swap/SwapModalHeaderAmount'
import { DEFAULT_CHAIN_ID } from 'constants/misc'
import { ToUSD } from 'hooks/useV3Positions'
import { useTokenFromActiveNetwork } from 'lib/hooks/useCurrency'
import { useIsMobile } from 'nft/hooks'
import { useEffect, useState } from 'react'
import { useUSDPricesWithFallback } from 'state/cache/hooks'
import styled from 'styled-components'
import { ExternalLinkIcon, ThemedText } from 'theme'
import { formatNumberOrString, NumberType } from 'utils/formatNumbers'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

import { ResponsiveButton } from './PositionsTable'

type RewardDisplayProps = {
  rewardResults: string // Computed reward result as a string
  rewardToken: string // The token in which the rewards are issued
  onClaim: () => void // Handler for claim action
}

const RewardDisplay: React.FC<RewardDisplayProps> = ({ rewardResults, rewardToken, onClaim }: RewardDisplayProps) => {
  const { chainId } = useWeb3React()
  const rewardTokenQuery = useTokenFromActiveNetwork(rewardToken) as Token
  const [rewardsInUSD, setRewardsInUSD] = useState<string>('0')
  const [rewardsUSDValue, setRewardsUSDValue] = useState<string>('0')
  const [hasRewards, setHasRewards] = useState<boolean>(false)
  const isMobile = useIsMobile()
  const cachedTokenPrices = useUSDPricesWithFallback()

  useEffect(() => {
    if (rewardTokenQuery && rewardResults) {
      const rewardsAmount = CurrencyAmount.fromRawAmount(rewardTokenQuery, rewardResults)
      const value = formatNumberOrString(rewardsAmount.toSignificant(), NumberType.TokenNonTx)
      setRewardsInUSD(value)

      const usdValue = ToUSD(cachedTokenPrices, rewardsAmount)
      setRewardsUSDValue(usdValue ? `${formatNumberOrString(usdValue, NumberType.FiatTokenPrice)}` : '-')

      // Check if there are any rewards
      // TODO: fix this, it regards dust as 0 and mistakenly marks it as no rewards
      setHasRewards(parseFloat(value) > 0)
    }
  }, [rewardResults, rewardTokenQuery, cachedTokenPrices])

  return (
    <RewardCard>
      <AutoColumn gap="12px">
        <ResponsiveRowBetween>
          <RewardTextContainer>
            <Label>
              <Trans>Your Claimed (Harvestable) Rewards</Trans>
            </Label>
            <CurrencyLogo currency={rewardTokenQuery} size={isMobile ? '30px' : '40px'} />
            <ThemedText.DeprecatedLargeHeader
              fontWeight="600"
              fontSize={isMobile ? '18px' : '20px'}
              lineHeight={isMobile ? '24px' : '28px'}
            >
              <Trans>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {rewardsInUSD} {rewardTokenQuery.symbol}
                  </div>
                  <ExternalLinkIcon
                    href={`${getExplorerLink(
                      chainId ?? DEFAULT_CHAIN_ID,
                      rewardTokenQuery.address,
                      ExplorerDataType.TOKEN
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                </div>
              </Trans>
            </ThemedText.DeprecatedLargeHeader>
            <USDValueText title={rewardsUSDValue}>≈ {rewardsUSDValue}</USDValueText>
          </RewardTextContainer>

          {/* Harvest (withdraw rewards) Button */}
          <ResponsiveButton onClick={onClaim}>
            <Trans>Harvest</Trans>
          </ResponsiveButton>
        </ResponsiveRowBetween>
      </AutoColumn>
    </RewardCard>
  )
}

export default RewardDisplay

// Styled Components

const RewardCard = styled(LightCard)`
  background-color: ${({ theme }) => theme.surface2};
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`

const RewardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
`

const ResponsiveRowBetween = styled(RowBetween)`
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`

const StyledButtonPrimary = styled(ButtonPrimary)`
  background-color: ${({ theme }) => theme.accent1};
  color: white;
  font-weight: 500;
  border: none;
  transition: background-color 0.2s, transform 0.1s;

  &:hover {
    background-color: ${({ theme }) => theme.accent2};
    transform: scale(1.03);
  }

  &:active {
    background-color: ${({ theme }) => theme.surface3};
    transform: scale(1);
  }
`

const USDValueText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.neutral2};
  cursor: help;
`

const TokenLink = styled.a`
  color: ${({ theme }) => theme.neutral1};
  cursor: help;
  text-decoration: none;
  border-bottom: 2px dashed ${({ theme }) => theme.neutral2};
  padding-bottom: 1px;
  margin-bottom: -1px;

  &:hover {
    color: ${({ theme }) => theme.accent1};
    border-bottom-color: ${({ theme }) => theme.accent1};
  }
`
