import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { Trace } from 'analytics'
import FullFarmCard from 'components/FarmCard'
import InvalidFarmDetails from 'components/Farms/FarmDetails/InvalidFarmDetails'
import FarmDetailsSkeleton from 'components/Farms/FarmDetails/Skeleton'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import AddressSection from 'components/Tokens/TokenDetails/AddressSection'
import BalanceSummary from 'components/Tokens/TokenDetails/BalanceSummary'
import { BreadcrumbNavLink } from 'components/Tokens/TokenDetails/BreadcrumbNavLink'
import MobileBalanceSummaryFooter from 'components/Tokens/TokenDetails/MobileBalanceSummaryFooter'
import ShareButton from 'components/Tokens/TokenDetails/ShareButton'
import {
  Hr,
  LeftPanel,
  RightPanel,
  TokenDetailsLayout,
  TokenInfoContainer,
  TokenNameCell,
} from 'components/Tokens/TokenDetails/Skeleton'
import { NATIVE_CHAIN_ID, nativeOnChain } from 'constants/tokens'
import { TokenQueryData } from 'graphql/data/Token'
import { QueryToken } from 'graphql/data/Token'
import { useFarmFetchHelper } from 'hooks/useContract'
import { usePairAddresstoCurrency } from 'hooks/usePairAddresstoCurrency'
import { useV2Pair } from 'hooks/useV2Pairs'
import JSBI from 'jsbi'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { UNKNOWN_TOKEN_SYMBOL, useTokenFromActiveNetwork } from 'lib/hooks/useCurrency'
import AddLiquidityWidget from 'pages/AddLiquidityV2/widget'
import { useCallback, useEffect, useMemo, useTransition } from 'react'
import { ArrowLeft } from 'react-feather'
import { useMatchingFarmFromPair } from 'state/farm/hooks'
import styled from 'styled-components'
import { isAddress } from 'utils'

const TokenSymbol = styled.span`
  text-transform: uppercase;
  color: ${({ theme }) => theme.neutral2};
  margin-left: 8px;
`
const TokenActions = styled.div`
  display: flex;
  gap: 16px;
  color: ${({ theme }) => theme.neutral2};
`
const TokenTitle = styled.div`
  display: flex;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: inline;
  }
`

function useOnChainToken(address: string | undefined, skip: boolean) {
  const token = useTokenFromActiveNetwork(skip || !address ? undefined : address)

  if (skip || !address || (token && token?.symbol === UNKNOWN_TOKEN_SYMBOL)) {
    return undefined
  } else {
    return token
  }
}

// Selects most relevant token based on data available, preferring native > query > on-chain
// Token will be null if still loading from on-chain, and undefined if unavailable
function useRelevantToken(
  address: string | undefined,
  pageChainId: number,
  tokenQueryData: TokenQueryData | undefined
) {
  const { chainId: activeChainId } = useWeb3React()
  const queryToken = useMemo(() => {
    if (!address) return undefined
    if (address === NATIVE_CHAIN_ID) return nativeOnChain(pageChainId)
    if (tokenQueryData) return new QueryToken(address, tokenQueryData)
    return undefined
  }, [pageChainId, address, tokenQueryData])
  // fetches on-chain token if query data is missing and page chain matches global chain (else fetch won't work)
  const skipOnChainFetch = Boolean(queryToken) || pageChainId !== activeChainId
  const onChainToken = useOnChainToken(address, skipOnChainFetch)

  return useMemo(
    () => ({ token: queryToken ?? onChainToken, didFetchFromChain: !queryToken }),
    [onChainToken, queryToken]
  )
}

type FarmDetailsProps = {
  currencyIdA?: string
  currencyIdB?: string
  pid?: string
  version?: string
}
export default function FarmDetails({ currencyIdA, currencyIdB, pid, version }: FarmDetailsProps) {
  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.addedNodes.length) {
          const currencyElement = document.querySelector('[class^="AssetLogo__MissingImageLogo"]')
          if (currencyElement && currencyElement.textContent === 'UNI') {
            currencyElement.textContent = 'spLP'
          }
        }
      }
    })

    // Start observing the document with the configured parameters
    observer.observe(document.body, { childList: true, subtree: true })

    // Clean up the observer when the component is unmounted
    return () => observer.disconnect()
  }, [])
  const [currencyA, currencyB] = usePairAddresstoCurrency(currencyIdA, currencyIdB)
  const [, pair] = useV2Pair(currencyA, currencyB)
  const lpAddress = useMemo(() => isAddress(pair?.liquidityToken.address) || undefined, [pair])
  const matchingFarm = useMatchingFarmFromPair(lpAddress, version, pid)

  const handleCurrencySelect = useCallback((currency: Currency) => {
    console.log('Currency Select should be disabled')
  }, [])

  const { account } = useWeb3React()
  let { chainId: pageChainId } = useWeb3React()
  pageChainId = pageChainId ?? 1

  // Wrapping navigate in a transition prevents Suspense from unnecessarily showing fallbacks again.
  const [isPending, startTokenTransition] = useTransition()
  const farmFetchHelper = useFarmFetchHelper()
  const isFetchUserBalanceValid = farmFetchHelper || account || (pid !== undefined && version != undefined)
  const stakedAmountBN: any | undefined = useSingleCallResult(
    isFetchUserBalanceValid ? farmFetchHelper : null,
    'fetchUserFarmData',
    [account, pid, version]
  ).result?.[0]

  const stakedAmount =
    pair && stakedAmountBN
      ? CurrencyAmount.fromRawAmount(pair.liquidityToken, JSBI.BigInt(stakedAmountBN.toString()))
      : undefined //TODO: Get the current staked Amount

  const rewardAmount = pair ? CurrencyAmount.fromRawAmount(pair.liquidityToken, JSBI.BigInt(500000)) : undefined //TODO: Get the current reward Amount

  // address will never be undefined if token is defined; address is checked here to appease typechecker
  if (pair?.liquidityToken === undefined || !lpAddress || !pair) {
    return <InvalidFarmDetails pageChainId={pageChainId} isInvalidAddress={!lpAddress} />
  }
  // Inside your component

  return (
    <Trace
      page="farm-details-page"
      properties={{ tokenAddress: lpAddress, tokenName: pair?.liquidityToken?.name }}
      shouldLogImpression
    >
      <TokenDetailsLayout>
        {pair?.liquidityToken && !isPending ? (
          <LeftPanel>
            <BreadcrumbNavLink to="/farms/v2">
              <ArrowLeft data-testid="token-details-return-button" size={14} /> Farms
            </BreadcrumbNavLink>
            <TokenInfoContainer data-testid="token-info-container">
              <TokenNameCell>
                <CurrencyLogo currency={pair?.liquidityToken} size="32px" hideL2Icon={false} />
                <TokenTitle>
                  {pair?.liquidityToken.name == undefined ? (
                    <Trans>Name not found</Trans>
                  ) : pair?.liquidityToken.name == 'Uniswap V2' ? (
                    'Spookyswap V2'
                  ) : (
                    pair?.liquidityToken.name
                  )}
                  <TokenSymbol>
                    {pair?.liquidityToken.symbol == undefined ? (
                      <Trans>Symbol not found</Trans>
                    ) : pair?.liquidityToken.symbol == 'UNI-V2' ? (
                      'SPOOKY-V2'
                    ) : (
                      pair?.liquidityToken.symbol
                    )}
                  </TokenSymbol>
                </TokenTitle>
              </TokenNameCell>
              <TokenActions>
                <ShareButton currency={pair?.liquidityToken} />
              </TokenActions>
            </TokenInfoContainer>
            <Hr />
            <AddressSection address={lpAddress} />
            {matchingFarm && (
              <FullFarmCard
                key={1}
                pair={pair}
                stakedBalance={stakedAmount}
                rewardBalance={rewardAmount}
                matchingFarm={matchingFarm}
              />
            )}
          </LeftPanel>
        ) : (
          <FarmDetailsSkeleton />
        )}

        <RightPanel>
          <div style={{ pointerEvents: 'auto' }}>
            <AddLiquidityWidget
              currencyIdA={currencyIdA}
              currencyIdB={currencyIdB}
              handleCurrencyASelect={handleCurrencySelect}
              handleCurrencyBSelect={handleCurrencySelect}
              currencySelectDisabled={true}
            />
          </div>
          {pair.liquidityToken && <BalanceSummary token={pair.liquidityToken} />}
        </RightPanel>
        {pair.liquidityToken && <MobileBalanceSummaryFooter token={pair.liquidityToken} />}
      </TokenDetailsLayout>
    </Trace>
  )
}
