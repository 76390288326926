// @deprecated in favor of useScreenSize
import { useScreenSize } from 'hooks/useScreenSize'

export function useIsNavSearchInputVisible(): boolean {
  const isScreenSize = useScreenSize()
  // TODO: hide search nav till useable
  const hideNav = true
  if (hideNav) {
    return false
  }

  return isScreenSize.navSearchInputVisible
}
