// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import 'react-datepicker/dist/react-datepicker.css'

// Add these imports at the top
import { MaxUint256 } from '@ethersproject/constants'
import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount, V3_CORE_FACTORY_ADDRESSES } from '@uniswap/sdk-core'
import { FeeAmount } from '@uniswap/v3-sdk'
// Add these imports at the top
import { computePoolAddress } from '@uniswap/v3-sdk'
import { useWeb3React } from '@web3-react/core'
import { ButtonGray, ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import Modal from 'components/Modal'
import { SkeletonRow } from 'components/NavBar/SuggestionRow'
import { Input } from 'components/NumericalInput'
import RewardsTypeToggle from 'components/RewardsTypeToggle'
import { RowBetween, RowFixed, RowFlat } from 'components/Row'
import CurrencySearchModal from 'components/SearchModal/CurrencySearchModal'
import { MEDIUM_MEDIA_BREAKPOINT, SMALL_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { isSupportedChain } from 'constants/chains'
import { WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import { BigNumber } from 'ethers/lib/ethers'
import { defaultAbiCoder, keccak256, parseUnits } from 'ethers/lib/utils'
import { useCurrency } from 'hooks/Tokens'
import { useFarmV3Helper } from 'hooks/useContract'
import { useTokenContract } from 'hooks/useContract'
import { useV3PositionFromTokenId } from 'hooks/useV3Positions'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { ConfirmAddFarmModalBottom } from 'pages/FarmV3/FarmDetails/ConfirmAddModalBottom'
import { FarmAction } from 'pages/FarmV3/FarmDetails/details'
import { toUSD } from 'pages/FarmV3/FarmTable/FarmRow'
import { darken } from 'polished'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import DatePicker from 'react-datepicker'
// Add these imports at the top
import { AlertTriangle, CheckCircle, ExternalLink as LinkIcon } from 'react-feather'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Text } from 'rebass'
import { useUSDPricesWithFallback } from 'state/cache/hooks'
import { Bound, Field } from 'state/mint/v3/actions'
import {
  useRangeHopCallbacks,
  useV3DerivedMintInfo,
  useV3MintActionHandlers,
  useV3MintState,
} from 'state/mint/v3/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled, { useTheme } from 'styled-components'
import { CopyLinkIcon } from 'theme'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { currencyId } from 'utils/currencyId'
import { formatUSDPrice } from 'utils/formatNumbers'
import { maxAmountSpend } from 'utils/maxAmountSpend'

import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'
import FeeSelector from '../../components/FeeSelector'
import { useDerivedPositionInfo } from '../../hooks/useDerivedPositionInfo'

// Add these new types after the imports
type SubmissionStatus = 'idle' | 'checking' | 'approving' | 'approved' | 'creating' | 'success' | 'error' | 'warning'

// Add these styled components
const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-radius: 12px;
  background: ${({ theme }) => theme.surface2};
  margin-bottom: 16px;
`

const StatusIcon = styled.div<{ status: SubmissionStatus }>`
  display: flex;
  align-items: center;
  color: ${({ status, theme }) => {
    switch (status) {
      case 'success':
        return theme.success
      case 'error':
        return theme.critical
      case 'warning':
        return theme.deprecated_yellow3
      case 'approving':
      case 'creating':
        return theme.accent1
      default:
        return theme.neutral2
    }
  }};
`

const StatusText = styled.span<{ status: SubmissionStatus }>`
  color: ${({ status, theme }) => {
    switch (status) {
      case 'success':
        return theme.success
      case 'error':
        return theme.critical
      case 'warning':
        return theme.deprecated_yellow3
      case 'approving':
      case 'creating':
        return theme.accent1
      default:
        return theme.neutral2
    }
  }};
  font-size: 14px;
  font-weight: 500;
  &::after {
    content: '...';
  }
  overflow: hidden;
  width: 100%;
`

const LoadingDots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`

// Add this component
const StatusIndicator = ({ status, error }: { status: SubmissionStatus; error?: string }) => {
  const getMessage = () => {
    switch (status) {
      case 'checking':
        return 'Checking allowance and parameters'
      case 'approving':
        return 'Waiting for token approval'
      case 'approved':
        return 'Token approved'
      case 'creating':
        return 'Creating farm - Please wait'
      case 'success':
        return 'Farm created successfully'
      case 'warning':
        return error || 'Warning: Limited farm visibility'
      case 'error':
        return error || 'An error occurred'
      default:
        return ''
    }
  }

  return status !== 'idle' ? (
    <StatusContainer>
      <StatusIcon status={status}>
        {status === 'success' ? (
          <CheckCircle size={16} />
        ) : status === 'error' ? (
          <AlertTriangle size={16} />
        ) : status === 'warning' ? (
          <AlertTriangle size={16} />
        ) : null}
      </StatusIcon>
      <StatusText status={status}>
        {getMessage()}
        {['checking', 'approving', 'creating'].includes(status) && <LoadingDots />}
      </StatusText>
    </StatusContainer>
  ) : null
}

// Add these constants
const MINIMUM_DAILY_REWARD_USD = 5

// Add these styled components near other styled components
const RewardEstimateBox = styled.div<{ isRewardSufficient: boolean }>`
  background: ${({ theme }) => theme.surface2};
  border-radius: 12px;
  padding: 12px 16px;
  margin-top: 8px;
  font-size: 14px;
  ${({ theme, isRewardSufficient }) =>
    !isRewardSufficient &&
    `
    border: 1px solid ${theme.deprecated_yellow1};
  `}
`

const ValidationMessage = styled.div<{ isValid: boolean }>`
  color: ${({ theme, isValid }) => (isValid ? theme.success : theme.critical)};
  font-size: 14px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 6px;
`

// Add these helper functions outside the component
const calculateTotalFromDaily = (dailyAmount: string, days: number): string => {
  if (!dailyAmount || !days) return ''
  const dailyNum = parseFloat(dailyAmount)
  return (dailyNum * days).toString()
}

const calculateDailyFromTotal = (totalAmount: string, days: number): string => {
  if (!totalAmount || !days) return ''
  const totalNum = parseFloat(totalAmount)
  return (totalNum / days).toString()
}

const getDurationInDays = (startDate: Date | null, endDate: Date | null): number => {
  if (!startDate || !endDate) return 0
  return Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24))
}

const SubmitFarm: React.FC = () => {
  const { account, chainId, provider } = useWeb3React()
  const navigate = useNavigate()
  const theme = useTheme()

  const [rewardToken, setRewardToken] = useState('')
  const [rewardAmount, setRewardAmount] = useState('')
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [loading, setLoading] = useState(false)
  const [rewardType, setRewardType] = useState<'perDay' | 'total'>('total') // New state for reward type
  const [attemptingTxn, setAttemptingTxn] = useState(false)
  const [txHash, setTxHash] = useState('')
  const [showConfirm, setShowConfirm] = useState(false)
  const cachedTokenPrices = useUSDPricesWithFallback()

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setAttemptingTxn(false)
    setTxHash('')
  }, [])
  const {
    currencyIdA,
    currencyIdB,
    feeAmount: feeAmountFromUrl,
    tokenId,
  } = useParams<{
    currencyIdA?: string
    currencyIdB?: string
    feeAmount?: string
    tokenId?: string
  }>()

  const { position: existingPositionDetails, loading: positionLoading } = useV3PositionFromTokenId(
    tokenId ? BigNumber.from(tokenId) : undefined
  )
  const { position: existingPosition } = useDerivedPositionInfo(existingPositionDetails)

  const feeAmount: FeeAmount | undefined =
    feeAmountFromUrl && Object.values(FeeAmount).includes(parseFloat(feeAmountFromUrl))
      ? parseFloat(feeAmountFromUrl)
      : undefined

  const baseCurrency = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)
  // prevent an error if they input ETH/WETH
  const quoteCurrency =
    baseCurrency && currencyB && baseCurrency.wrapped.equals(currencyB.wrapped) ? undefined : currencyB

  const {
    pool,
    ticks,
    dependentField,
    pricesAtTicks,
    pricesAtLimit,
    parsedAmounts,
    currencyBalances,
    noLiquidity,
    currencies,
  } = useV3DerivedMintInfo(
    baseCurrency ?? undefined,
    quoteCurrency ?? undefined,
    feeAmount,
    baseCurrency ?? undefined,
    existingPosition
  )
  const { onFieldAInput, onFieldBInput, onLeftRangeInput, onRightRangeInput } = useV3MintActionHandlers(noLiquidity)

  const { independentField, typedValue } = useV3MintState()

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  const handleCurrencySelect = useCallback(
    (currencyNew: Currency, currencyIdOther?: string): (string | undefined)[] => {
      const currencyIdNew = currencyId(currencyNew)

      if (currencyIdNew === currencyIdOther) {
        return [currencyIdNew, undefined]
      } else {
        const isETHOrWETHNew =
          currencyIdNew === 'ETH' ||
          (chainId !== undefined && currencyIdNew === WRAPPED_NATIVE_CURRENCY[chainId]?.address)
        const isETHOrWETHOther =
          currencyIdOther !== undefined &&
          (currencyIdOther === 'ETH' ||
            (chainId !== undefined && currencyIdOther === WRAPPED_NATIVE_CURRENCY[chainId]?.address))

        if (isETHOrWETHNew && isETHOrWETHOther) {
          return [currencyIdNew, undefined]
        } else {
          return [currencyIdNew, currencyIdOther]
        }
      }
    },
    [chainId]
  )

  const handleCurrencyASelect = useCallback(
    (currencyANew: Currency) => {
      const [idA, idB] = handleCurrencySelect(currencyANew, currencyIdB)
      if (idB === undefined) {
        navigate(`/submit-farm/${idA}`)
      } else {
        navigate(`/submit-farm/${idA}/${idB}`)
      }
    },
    [handleCurrencySelect, currencyIdB, navigate]
  )

  const handleCurrencyBSelect = useCallback(
    (currencyBNew: Currency) => {
      const [idB, idA] = handleCurrencySelect(currencyBNew, currencyIdA)
      if (idA === undefined) {
        navigate(`/submit-farm/${idB}`)
      } else {
        navigate(`/submit-farm/${idA}/${idB}`)
      }
    },
    [handleCurrencySelect, currencyIdA, navigate]
  )

  const maxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field]),
      }
    },
    {}
  )

  const atMaxAmounts: { [field in Field]?: CurrencyAmount<Currency> } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
      }
    },
    {}
  )

  const [searchParams, setSearchParams] = useSearchParams()

  const { [Bound.LOWER]: tickLower, [Bound.UPPER]: tickUpper } = ticks
  const { [Bound.LOWER]: priceLower, [Bound.UPPER]: priceUpper } = pricesAtTicks

  const { getSetFullRange } = useRangeHopCallbacks(
    baseCurrency ?? undefined,
    quoteCurrency ?? undefined,
    feeAmount,
    tickLower,
    tickUpper,
    pool
  )

  const handleSetFullRange = useCallback(() => {
    getSetFullRange()

    const minPrice = pricesAtLimit[Bound.LOWER]
    if (minPrice) searchParams.set('minPrice', minPrice.toSignificant(5))
    const maxPrice = pricesAtLimit[Bound.UPPER]
    if (maxPrice) searchParams.set('maxPrice', maxPrice.toSignificant(5))
    setSearchParams(searchParams)
  }, [getSetFullRange, pricesAtLimit, searchParams, setSearchParams])

  const handleFeePoolSelect = useCallback(
    (newFeeAmount: FeeAmount) => {
      onLeftRangeInput('')
      onRightRangeInput('')
      handleSetFullRange()
      navigate(`/submit-farm/${currencyIdA}/${currencyIdB}/${newFeeAmount}`)
    },
    [currencyIdA, currencyIdB, handleSetFullRange, navigate, onLeftRangeInput, onRightRangeInput]
  )

  // const handleConfirmSubmit = () => {
  //   setLoading(true)
  //   // Simulate submission
  //   setTimeout(() => {
  //     setLoading(false)
  //     toast.success('Farm submitted successfully')
  //     navigate('/farms')
  //   }, 2000)
  // }

  // const handleSubmit2 = () => {
  //   if (!account) {
  //     toast.error('Please connect your wallet')
  //     return
  //   }

  //   const poolAddress = computePoolAddressFromTokens()
  //   if (!poolAddress) {
  //     toast.error('Please select valid tokens and fee tier')
  //     return
  //   }

  //   if (!selectedCurrency || !rewardAmount || !startDate || !endDate) {
  //     toast.error('Please fill in all fields')
  //     return
  //   }

  //   if // (isNaN(Number(rewardAmount)) || Number(rewa// rdAmount) <= 0) {
  //     toast.error(// 'Please enter a valid reward amount')
  //     return
  //   }

  //   if (startDate >= endDate) {
  //     toast.er// ror('Start date must be before the end date')
  //     return
  //   }

  //   //   // Check if we have sufficient allowance
  //   if (currentAllowance && rewardAmountCurrency && currentAllowance.lessThan(rewardAmountCurrency)) {
  //     // Need approval first
  //     handleApprove()
  //   } else {
  //     // Already approved, create incentive
  //     handleCreateIncentive()
  //   }
  // }

  const [rewardsOption, setRewardsOption] = useState(true) // Toggle control

  // // Function to handle reward type toggle
  // const handleAdvancedOption = () => {
  //   setRewardType((prevType) => (prevType === 'total' ? 'perDay' : 'total')) // Toggle between 'total' and 'perDay'
  //   setRewardsOption(!rewardsOption) // Toggle the rewardsOption for UI
  // }

  const [modalOpen, setModalOpen] = useState(false)
  const chainAllowed = isSupportedChain(chainId)
  const hideInput = false
  const selectDisabled = false

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const [selectedCurrency, setSelectedCurrency] = useState<Currency | null>(null) // State for selected currency

  const handleCurrencySelection = (currency: Currency) => {
    setSelectedCurrency(currency)
    // Reset status and errors when changing token
    setSubmissionStatus('idle')
    setErrorMessage('')
  }

  const currency = selectedCurrency

  // Add this function inside the SubmitFarm component
  const computePoolAddressFromTokens = useCallback(() => {
    if (!chainId || !baseCurrency || !quoteCurrency || !feeAmount) return undefined
    const factoryAddress = V3_CORE_FACTORY_ADDRESSES[chainId]

    try {
      return computePoolAddress({
        factoryAddress,
        tokenA: baseCurrency.wrapped,
        tokenB: quoteCurrency.wrapped,
        fee: feeAmount,
      })
    } catch (error) {
      console.error('Failed to compute pool address:', error)
      return undefined
    }
  }, [chainId, baseCurrency, quoteCurrency, feeAmount])

  // Add the create incentive function
  const uniV3StakerContract = useFarmV3Helper()
  const addTransaction = useTransactionAdder()

  const modalHeader = () => {
    // Calculate rewards per day
    const calculateRewardsPerDay = () => {
      if (!startDate || !endDate || !rewardAmount) return '0'
      const days = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24))
      return (Number(rewardAmount) / days).toFixed(4)
    }

    return (
      <AutoColumn gap="20px">
        <LightCard mt="20px" $borderRadius="20px">
          <RowFlat style={{ alignItems: 'center', gap: '15px', marginBottom: '16px' }}>
            <Text fontSize={24} fontWeight={500}>
              🎃 Confirm Farm Creation
            </Text>
          </RowFlat>

          {/* Pool Information */}
          <RowFlat style={{ marginTop: '12px' }}>
            <Text fontSize={16} fontWeight={500} color={theme.neutral2}>
              Target LP Pool:
            </Text>
          </RowFlat>
          <RowFlat style={{ marginTop: '8px', marginLeft: '8px' }}>
            <DoubleCurrencyLogo currency0={baseCurrency ?? undefined} currency1={currencyB ?? undefined} size={20} />
            <Text marginLeft="8px">
              {baseCurrency?.symbol}/{currencyB?.symbol} ({feeAmount ? feeAmount / 10000 : ''}%)
            </Text>
          </RowFlat>

          {/* Reward Information */}
          <RowFlat style={{ marginTop: '16px' }}>
            <Text fontSize={16} fontWeight={500} color={theme.neutral2}>
              Reward Details:
            </Text>
          </RowFlat>
          <RowFlat style={{ marginTop: '8px', marginLeft: '8px', flexDirection: 'row', alignItems: 'center' }}>
            <CurrencyLogo hideL2Icon={false} currency={selectedCurrency ?? undefined} size="20px" />
            <Text marginLeft="8px">
              {rewardAmount} {selectedCurrency?.symbol}
            </Text>
          </RowFlat>
          <RowFlat style={{ marginTop: '4px', marginLeft: '8px' }}>
            <Text fontSize={14} color={theme.neutral2}>
              ≈ {calculateRewardsPerDay()} {selectedCurrency?.symbol} per day
            </Text>
          </RowFlat>

          {/* Duration Information */}
          <RowFlat style={{ marginTop: '16px' }}>
            <Text fontSize={16} fontWeight={500} color={theme.neutral2}>
              Farm Duration:
            </Text>
          </RowFlat>
          <RowFlat style={{ marginTop: '8px', marginLeft: '8px' }}>
            <Text>
              {startDate?.toLocaleDateString()} → {endDate?.toLocaleDateString()}
            </Text>
          </RowFlat>
          <RowFlat style={{ marginTop: '4px', marginLeft: '8px' }}>
            <Text fontSize={14} color={theme.neutral2}>
              ({Math.ceil((endDate?.getTime() ?? 0) - (startDate?.getTime() ?? 0)) / (1000 * 60 * 60 * 24)} days)
            </Text>
          </RowFlat>

          {/* Warning/Note */}
          <RowFlat
            style={{ marginTop: '16px', padding: '12px', background: `${theme.surface2}50`, borderRadius: '12px' }}
          >
            <Text fontSize={14} color={theme.neutral2} style={{ fontStyle: 'italic' }}>
              ⚠️ Make sure you have approved enough {selectedCurrency?.symbol} tokens for the entire reward duration.
              This action cannot be undone.
            </Text>
          </RowFlat>
        </LightCard>
      </AutoColumn>
    )
  }

  const modalBottom = () => {
    if (baseCurrency && currencyB && selectedCurrency) {
      return (
        <ConfirmAddFarmModalBottom
          formattedTokenName={`${baseCurrency.symbol}/${currencyB.symbol}`}
          lpToken={currencyB}
          quoteToken={baseCurrency}
          farmAction={FarmAction.Create}
          onExecute={async () => {
            setShowConfirm(false)
            setSubmissionStatus('creating')
            await handleCreateIncentive()
          }}
        />
      )
    } else {
      return <SkeletonRow />
    }
  }

  // Add these functions inside the SubmitFarm component
  const tokenContract = useTokenContract(selectedCurrency?.wrapped.address)

  const allowance = useSingleCallResult(tokenContract, 'allowance', [
    account ?? undefined,
    uniV3StakerContract?.address ?? undefined,
  ])?.result?.[0]

  const currentAllowance =
    selectedCurrency && allowance
      ? CurrencyAmount.fromRawAmount(selectedCurrency.wrapped, allowance.toString())
      : undefined

  const rewardAmountCurrency = useMemo(() => {
    if (!selectedCurrency || !rewardAmount) return undefined
    try {
      const parsedAmount = parseUnits(rewardAmount, selectedCurrency.wrapped.decimals).toString()
      return CurrencyAmount.fromRawAmount(selectedCurrency.wrapped, parsedAmount)
    } catch (error) {
      console.error('Failed to parse reward amount:', error)
      return undefined
    }
  }, [selectedCurrency, rewardAmount])
  // Update the handleCreateIncentive function
  const handleCreateIncentive = useCallback(async () => {
    if (
      !chainId ||
      !provider ||
      !account ||
      !uniV3StakerContract ||
      !startDate ||
      !endDate ||
      !rewardAmount ||
      !selectedCurrency
    ) {
      setSubmissionStatus('error')
      setErrorMessage('Missing required parameters for creating incentive')
      return
    }

    const poolAddress = computePoolAddressFromTokens()
    if (!poolAddress) {
      setSubmissionStatus('error')
      setErrorMessage('Failed to compute pool address')
      return
    }

    try {
      setSubmissionStatus('creating')

      const startTimeSeconds = Math.floor(startDate.getTime() / 1000)
      const endTimeSeconds = Math.floor(endDate.getTime() / 1000)

      // Parse the reward amount with the correct number of decimals
      const rewardAmountInWei = parseUnits(rewardAmount, selectedCurrency.wrapped.decimals)

      // Compute incentiveKey before creating incentive
      const incentiveKeyArgs = [
        selectedCurrency.wrapped.address, // rewardToken
        poolAddress, // pool
        startTimeSeconds.toString(), // startTime
        endTimeSeconds.toString(), // endTime
        account, // refundee
      ]

      // Encode the parameters according to their types
      const encodedData = defaultAbiCoder.encode(
        ['address', 'address', 'uint256', 'uint256', 'address'],
        incentiveKeyArgs
      )

      // Hash the encoded data to get the incentiveKey
      const incentiveKey = keccak256(encodedData)

      const args = [
        {
          rewardToken: selectedCurrency.wrapped.address,
          pool: poolAddress,
          startTime: startTimeSeconds.toString(),
          endTime: endTimeSeconds.toString(),
          refundee: account,
        },
        rewardAmountInWei,
      ] as const

      const estimatedGas = await uniV3StakerContract.estimateGas.createIncentive(...args)
      const response = await uniV3StakerContract.createIncentive(...args, {
        gasLimit: calculateGasMargin(estimatedGas),
      })

      addTransaction(response, {
        type: TransactionType.CREATE_FARM_V3,
        baseCurrencyId: baseCurrency?.wrapped.address ?? '',
        quoteCurrencyId: quoteCurrency?.wrapped.address ?? '',
      })
      setSubmissionStatus('creating')

      const receipt = await response.wait()
      console.log('🚀 ~ handleCreateIncentive ~ receipt:', receipt)

      setSubmissionStatus('success')
      handleDismissConfirmation()
      setShowSuccessModal(true)
      setCreatedIncentiveId(incentiveKey)
    } catch (error) {
      console.error('🚀 ~ handleCreateIncentive ~ error:', error)
      setSubmissionStatus('error')
      setErrorMessage('Failed to create farm')
    }
  }, [
    chainId,
    provider,
    account,
    uniV3StakerContract,
    startDate,
    endDate,
    rewardAmount,
    selectedCurrency,
    computePoolAddressFromTokens,
    addTransaction,
    baseCurrency?.wrapped.address,
    quoteCurrency?.wrapped.address,
    handleDismissConfirmation,
  ])

  // Update the button text based on approval status
  const getButtonText = () => {
    if (loading) return 'Submitting...'
    if (!account) return 'Connect Wallet'
    if (currentAllowance && rewardAmountCurrency && currentAllowance.lessThan(rewardAmountCurrency)) {
      return 'Approve Token'
    }
    return 'Submit Farm'
  }

  // In the SubmitFarm component, add these states
  const [submissionStatus, setSubmissionStatus] = useState<SubmissionStatus>('idle')
  const [errorMessage, setErrorMessage] = useState<string>('')

  // Update the handleApprove function
  const handleApprove = useCallback(async () => {
    if (!tokenContract || !uniV3StakerContract || !rewardAmountCurrency) {
      setSubmissionStatus('error')
      setErrorMessage('Missing dependencies for approval')
      return
    }

    try {
      setSubmissionStatus('approving')
      const estimatedGas = await tokenContract.estimateGas.approve(uniV3StakerContract.address, MaxUint256)

      const response = await tokenContract.approve(uniV3StakerContract.address, MaxUint256, {
        gasLimit: calculateGasMargin(estimatedGas),
      })

      addTransaction(response, {
        type: TransactionType.APPROVAL,
        tokenAddress: selectedCurrency?.wrapped.address ?? '',
        spender: uniV3StakerContract.address ?? '',
        amount: rewardAmountCurrency?.quotient.toString() ?? '',
      })

      await response.wait()
      setSubmissionStatus('approved')
      // We should not automatically create the incentive after approval, because then it doesn't show the confirmation window which contains the farm details
    } catch (error) {
      setSubmissionStatus('error')
      setErrorMessage('Failed to approve token')
    }
  }, [
    tokenContract,
    uniV3StakerContract,
    rewardAmountCurrency,
    selectedCurrency,
    addTransaction,
    handleCreateIncentive,
  ])

  // Add this state
  const [estimatedRewardUSD, setEstimatedRewardUSD] = useState<number>(0)

  // Add this helper function
  // const calculateDailyRewardUSD = useCallback(() => {
  //   if (!selectedCurrency || !rewardAmount || !startDate || !endDate) return 0

  //   let days = 0
  //   if (rewardType === 'total') {
  //     days = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24))
  //   } else if (rewardType === 'perDay') {
  //     days = 1
  //   }
  //   if (days <= 0) return 0

  //   try {
  //     // Convert reward amount to CurrencyAmount
  //     const rewardAmountInWei = parseUnits(
  //       rewardAmount.replace(/\.?0+$/, ''),
  //       selectedCurrency.wrapped.decimals
  //     ).toString()

  //     const currencyAmount = CurrencyAmount.fromRawAmount(selectedCurrency.wrapped, rewardAmountInWei)

  //     // Calculate total USD value using the same toUSD function

  //     const totalRewardsUSD = toUSD(cachedTokenPrices, currencyAmount)
  //     return totalRewardsUSD / days
  //   } catch (error) {
  //     console.error('Failed to calculate daily rewards:', error)
  //     return 0
  //   }
  // }, [selectedCurrency, rewardAmount, startDate, endDate, cachedTokenPrices, rewardType])

  // Update reward amount whenever relevant values change
  // useEffect(() => {
  //   const dailyRewardUSD = calculateDailyRewardUSD()
  //   setEstimatedRewardUSD(dailyRewardUSD)
  // }, [calculateDailyRewardUSD])

  // Add this validation helper
  const isRewardSufficient = useMemo(() => {
    return estimatedRewardUSD >= MINIMUM_DAILY_REWARD_USD
  }, [estimatedRewardUSD])

  // Update the handleSubmit function
  const handleSubmit = async () => {
    if (!account) {
      setSubmissionStatus('error')
      setErrorMessage('Please connect your wallet')
      return
    }

    try {
      setSubmissionStatus('checking')

      const now = new Date()
      now.setHours(0, 0, 0, 0)

      if (!startDate || !endDate) {
        setSubmissionStatus('error')
        setErrorMessage('Please select both start and end dates')
        return
      }

      if (startDate < now) {
        setSubmissionStatus('error')
        setErrorMessage('Start date cannot be in the past')
        return
      }

      if (endDate <= startDate) {
        setSubmissionStatus('error')
        setErrorMessage('End date must be after start date')
        return
      }

      if (!selectedCurrency || !rewardAmount) {
        setSubmissionStatus('error')
        setErrorMessage('Please select a reward token and enter reward amount')
        return
      }

      const poolAddress = computePoolAddressFromTokens()
      if (!poolAddress) {
        setSubmissionStatus('error')
        setErrorMessage('Please select valid tokens and fee tier')
        return
      }

      // // Add reward validation
      // if (!isRewardSufficient) {
      //   setSubmissionStatus('error')
      //   setErrorMessage(`Daily rewards must be at least ${formatUSDPrice(MINIMUM_DAILY_REWARD_USD)}`)
      //   return
      // }

      // Update reward validation to show warning instead of error
      if (!isRewardSufficient) {
        setSubmissionStatus('warning')
        setErrorMessage('Farm will be created but not listed publicly due to low daily rewards')
        // Allow to continue instead of return
      }

      // If all validations pass, show confirmation
      if (currentAllowance && rewardAmountCurrency) {
        if (currentAllowance.lessThan(rewardAmountCurrency)) {
          setSubmissionStatus('approving')
          await handleApprove()
        } else {
          // Show confirmation before creating incentive
          setShowConfirm(true)
        }
      }
    } catch (error) {
      setSubmissionStatus('error')
      setErrorMessage('Failed to submit Farm')
    }
  }

  // Add this helper function at component level
  const getMinDate = () => {
    const now = new Date()
    now.setMinutes(Math.ceil(now.getMinutes() / 15) * 15) // Round up to nearest 15 minutes
    return now
  }

  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [createdIncentiveId, setCreatedIncentiveId] = useState('')

  const FarmSuccessContent = ({ incentiveId, onDismiss }: { incentiveId: string; onDismiss: () => void }) => {
    const theme = useTheme()
    const farmUrl = `https://spooky.fi/#/farms/v3/${incentiveId}`
    const telegramUrl = 'https://t.me/SpookySwapOfficial' // Replace with actual telegram URL

    return (
      <SuccessModal>
        <RowBetween style={{ marginBottom: '20px' }}>
          <Text fontWeight={500} fontSize={20}>
            Farm Created Successfully! 🎉
          </Text>
          <IconButton onClick={onDismiss}>×</IconButton>
        </RowBetween>

        <Text color={theme.neutral2} style={{ marginBottom: '16px' }}>
          Your farm has been created and will be accessible in a few minutes. You can view it at:
        </Text>

        <LinkRow>
          <Text fontSize={14} style={{ width: '80%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {farmUrl}
          </Text>
          <RowFixed gap="8px">
            <CopyLinkIcon toCopy={farmUrl} />
            <ExternalLink href={farmUrl}>
              <LinkIcon size={16} />
            </ExternalLink>
          </RowFixed>
        </LinkRow>
        {/* 
        <Text color={theme.neutral2} style={{ marginTop: '24px', marginBottom: '16px' }}>
          To submit your farm for review, please contact the Spooky team:
        </Text>

        <ButtonPrimary
          as={ExternalLink}
          href={telegramUrl}
          style={{
            marginTop: '12px',
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            fontSize: '14px',
            color: theme.neutral1,
          }}
        >
          <TelegramIcon width="20" height="20" />
          Contact Spooky on Telegram
        </ButtonPrimary> */}
      </SuccessModal>
    )
  }

  // Add this helper function to filter past times
  const filterPassedTime = (time: Date) => {
    const currentDate = new Date()
    const selectedDate = new Date(time)

    return currentDate.getTime() < selectedDate.getTime()
  }

  // Add new state for internal reward tracking
  const [internalRewardAmount, setInternalRewardAmount] = useState('')

  // Update reward amount handler
  const handleRewardAmountChange = (value: string) => {
    setInternalRewardAmount(value)

    console.log('🚀 ~ handleRewardAmountChange ~ value:', value)

    const days = getDurationInDays(startDate, endDate)
    if (days > 0) {
      // Set the actual reward amount based on type
      if (rewardType === 'perDay') {
        setRewardAmount(calculateTotalFromDaily(value, days))
      } else {
        setRewardAmount(value)
      }
    } else {
      setRewardAmount(value)
    }

    console.log('🚀 ~ handleRewardAmountChange ~ rewardAmount:', rewardAmount, rewardType)
  }

  useEffect(() => {
    const days = getDurationInDays(startDate, endDate)
    if (days > 0 && rewardAmount) {
      if (rewardType === 'perDay') {
        setRewardAmount(calculateTotalFromDaily(internalRewardAmount, days))
      } else {
        setInternalRewardAmount(rewardAmount)
      }
    }
  }, [startDate, endDate, rewardType, rewardAmount])

  // Update the rewards calculation
  const calculateDailyRewardUSD = useCallback(() => {
    if (!selectedCurrency || !rewardAmount || !startDate || !endDate) return 0

    try {
      const days = getDurationInDays(startDate, endDate)
      if (days <= 0) return 0

      let dailyRewardAmount: string
      if (rewardType === 'perDay') {
        dailyRewardAmount = internalRewardAmount // Already daily amount
      } else {
        dailyRewardAmount = calculateDailyFromTotal(rewardAmount, days)
      }

      // Convert daily reward amount to CurrencyAmount
      // ensure daily rewards decimals not more than token decimals
      const cleanedDailyRewardAmount = dailyRewardAmount.split('.')[1]
        ? dailyRewardAmount.split('.')[0] +
          '.' +
          dailyRewardAmount.split('.')[1].slice(0, selectedCurrency.wrapped.decimals)
        : dailyRewardAmount

      const rewardAmountInWei = parseUnits(cleanedDailyRewardAmount, selectedCurrency.wrapped.decimals).toString()

      const currencyAmount = CurrencyAmount.fromRawAmount(selectedCurrency.wrapped, rewardAmountInWei)
      return toUSD(cachedTokenPrices, currencyAmount)
    } catch (error) {
      console.error('Failed to calculate daily rewards:', error)
      return 0
    }
  }, [selectedCurrency, rewardAmount, internalRewardAmount, startDate, endDate, rewardType, cachedTokenPrices])

  // Update reward amount whenever relevant values change
  useEffect(() => {
    const dailyRewardUSD = calculateDailyRewardUSD()
    setEstimatedRewardUSD(dailyRewardUSD)
  }, [calculateDailyRewardUSD])

  // Update the reward type toggle handler
  const handleAdvancedOption = useCallback(() => {
    const days = getDurationInDays(startDate, endDate)
    const newType = rewardType === 'total' ? 'perDay' : 'total'

    if (days > 0 && internalRewardAmount) {
      if (newType === 'perDay') {
        // Converting from total to per day
        setInternalRewardAmount(calculateDailyFromTotal(internalRewardAmount, days))
      } else {
        // Converting from per day to total
        setInternalRewardAmount(calculateTotalFromDaily(internalRewardAmount, days))
      }
    }

    setRewardType(newType)
    setRewardsOption(!rewardsOption)
  }, [rewardType, rewardsOption, startDate, endDate, internalRewardAmount])

  return (
    <>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash}
        reviewContent={() => (
          <ConfirmationModalContent
            title={<Trans>Create Community Farm</Trans>}
            onDismiss={handleDismissConfirmation}
            topContent={modalHeader}
            bottomContent={modalBottom}
          />
        )}
        pendingText={`Creating farm for ${baseCurrency?.symbol}/${currencyB?.symbol}`}
      />
      <Modal
        isOpen={showSuccessModal}
        onDismiss={() => {
          setShowSuccessModal(false)
        }}
      >
        <FarmSuccessContent
          incentiveId={createdIncentiveId}
          onDismiss={() => {
            setShowSuccessModal(false)
          }}
        />
      </Modal>
      <StyledContainer>
        <Card>
          <Title>Submit a Community Farm</Title>
          <Form>
            <Section>
              <AutoColumn gap="md">
                <RowBetweenRewardToken>
                  <Label>Reward Token</Label>
                  <RewardsTypeToggle
                    rewardsOption={rewardsOption}
                    handleRewardsTypeToggle={handleAdvancedOption} // Toggle logic linked here
                  />
                </RowBetweenRewardToken>

                <RowBetweenContainer>
                  <RowContainer>
                    <StyledInput
                      value={internalRewardAmount}
                      onUserInput={handleRewardAmountChange}
                      placeholder={rewardType === 'total' ? 'Total Reward Amount' : 'Rewards Per Day'}
                    />
                  </RowContainer>

                  <CurrencySelect
                    disabled={!chainAllowed || selectDisabled}
                    visible={true}
                    selected={!!useCurrency(rewardToken)}
                    hideInput={hideInput}
                    className="open-currency-select-button"
                    onClick={() => {
                      setModalOpen(true)
                    }}
                  >
                    <Aligner>
                      <RowFixed>
                        {selectedCurrency && (
                          <CurrencyLogo style={{ marginRight: '0.5rem' }} currency={currency} size="24px" />
                        )}

                        <StyledTokenName
                          className="token-symbol-container"
                          active={Boolean(currency && currency.symbol)}
                        >
                          {(currency && currency.symbol && currency.symbol.length > 20
                            ? currency.symbol.slice(0, 4) + '...' + currency.symbol.slice(currency.symbol.length - 5)
                            : currency?.symbol) || <Trans>Select a token</Trans>}
                        </StyledTokenName>
                      </RowFixed>
                      {!selectedCurrency && <StyledDropDown selected={!!currency} />}{' '}
                    </Aligner>
                  </CurrencySelect>
                </RowBetweenContainer>
                <CurrencySearchModal
                  disableNonToken={true}
                  isOpen={modalOpen}
                  onDismiss={handleDismissSearch}
                  onCurrencySelect={handleCurrencySelection} // Pass the selection handler
                />
              </AutoColumn>

              {selectedCurrency && rewardAmount && startDate && endDate && (
                <RewardEstimateBox isRewardSufficient>
                  <div>Estimated Daily Rewards: {formatUSDPrice(estimatedRewardUSD)}</div>
                  <ValidationMessage isValid={isRewardSufficient}>
                    {isRewardSufficient ? (
                      <>
                        <CheckCircle size={16} />
                        Farm meets minimum daily reward requirement to be publicly displayed in the community farms
                        list.
                      </>
                    ) : (
                      <>
                        <AlertTriangle size={16} />
                        Farm requires minimum daily rewards of {formatUSDPrice(MINIMUM_DAILY_REWARD_USD)} to be publicly
                        displayed in the community farms list. You can still create the farm, and share the link to it.
                      </>
                    )}
                  </ValidationMessage>
                </RewardEstimateBox>
              )}
            </Section>

            <Section>
              <Label>Start Date</Label>
              <StyledDatePicker
                showTimeSelect
                selected={startDate}
                popperClassName="z-1000"
                onChange={(date: Date | null) => {
                  if (date) {
                    setStartDate(date)
                    // If end date exists and is before new start date, update it
                    if (endDate && date > endDate) {
                      const newEndDate = new Date(date)
                      newEndDate.setDate(date.getDate() + 1) // Set end date to day after start date
                      setEndDate(newEndDate)
                    }
                  }
                }}
                placeholderText="Start Date"
                dateFormat="MMMM d, yyyy h:mm aa"
                minDate={getMinDate()} // Prevent past dates
                filterTime={filterPassedTime} // Add time filtering
                timeIntervals={15} // Set time intervals to 15 minutes
                timeCaption="Time"
                showDisabledMonthNavigation
                timeFormat="HH:mm"
              />
            </Section>

            <Section>
              <Label>End Date</Label>
              <StyledDatePicker
                showTimeSelect
                popperClassName="z-1000"
                selected={endDate}
                onChange={(date: Date | null) => {
                  if (date) {
                    // Only allow end date if it's after start date
                    if (!startDate || date > startDate) {
                      setEndDate(date)
                    } else {
                      toast.error('End date must be after start date')
                    }
                  }
                }}
                placeholderText="End Date"
                dateFormat="MMMM d, yyyy h:mm aa"
                minDate={startDate ? new Date(startDate.getTime() + 86400000) : getMinDate()} // Either day after start date or today
                timeIntervals={15} // Set time intervals to 15 minutes
                timeCaption="Time"
                showDisabledMonthNavigation
                timeFormat="HH:mm"
                disabled={!startDate} // Disable until start date is selected
              />
            </Section>

            <Section>
              <Label>LP Pair</Label>
              <AutoColumn gap="md">
                <RowBetweenRewardToken>
                  <CurrencyFarms
                    value={formattedAmounts[Field.CURRENCY_A]}
                    onUserInput={onFieldAInput}
                    hideInput={true}
                    onMax={() => {
                      onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
                    }}
                    onCurrencySelect={handleCurrencyASelect}
                    showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
                    currency={currencies[Field.CURRENCY_A] ?? null}
                    id="add-liquidity-input-tokena"
                    showCommonBases
                    disableNonToken={true}
                  />

                  <div style={{ width: '12px' }} />
                  <CurrencyFarms
                    value={formattedAmounts[Field.CURRENCY_B]}
                    hideInput={true}
                    onUserInput={onFieldBInput}
                    onCurrencySelect={handleCurrencyBSelect}
                    onMax={() => {
                      onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
                    }}
                    showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                    currency={currencies[Field.CURRENCY_B] ?? null}
                    id="add-liquidity-input-tokenb"
                    showCommonBases
                    disableNonToken={true}
                  />
                </RowBetweenRewardToken>
                <FeeSelector
                  disabled={!quoteCurrency || !baseCurrency}
                  feeAmount={feeAmount}
                  handleFeePoolSelect={handleFeePoolSelect}
                  currencyA={baseCurrency ?? undefined}
                  currencyB={quoteCurrency ?? undefined}
                  isOnCreateFarm={true}
                />
              </AutoColumn>

              {/* <StyledInput value={lpPair} onChange={(e) => setLpPair(e.target.value)} /> */}
            </Section>

            <StatusIndicator status={submissionStatus} error={errorMessage} />
            <ButtonPrimary
              onClick={handleSubmit}
              disabled={loading || !account || ['approving', 'creating'].includes(submissionStatus)}
              style={{ zIndex: '0' }}
            >
              {getButtonText()}
            </ButtonPrimary>
          </Form>
        </Card>
      </StyledContainer>
    </>
  )
}

// Styled Components
const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  padding: 20px;
  width: 80%;

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    width: 100%;
  }
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: 0px;
`

const StyledTokenName = styled.span<{ active?: boolean }>`
  // white-space: nowrap; /* Prevent text wrapping */
  ${({ active }) => (active ? '  margin: 0 0.25rem 0 0.25rem;' : '  margin: 0 0.25rem 0 0.25rem;')}
  font-size: 20px;
`

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.35rem;
  height: 35%;

  path {
    stroke: ${({ selected, theme }) => (selected ? theme.neutral1 : theme.white)};
    stroke-width: 1.5px;
  }
`

const Card = styled.div`
  background-color: ${({ theme }) => theme.surface1};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 30px;
  max-width: 600px;
  width: 100%;
  animation: fadeIn 0.5s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

const Title = styled.h2`
  text-align: center;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.accent1};
`

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
`

const RowContainer = styled.div`
  display: flex;
  width: 500px;

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    width: 100%;
    margin-bottom: 16px;
    margin-left: 24px;
  }
`

const StyledInput = styled(Input)`
  background-color: ${({ theme }) => theme.surface2};
  text-align: left;
  font-size: 18px;
  width: 100%;
`

const CurrencySelect = styled(ButtonGray)<{
  visible: boolean
  selected: boolean
  hideInput?: boolean
  disabled?: boolean
  pointerEvents?: string
}>`
  align-items: center;
  background-color: ${({ selected, theme }) => (selected ? theme.surface1 : theme.accent1)};
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
  box-shadow: ${({ theme }) => theme.deprecated_shallowShadow};
  color: ${({ selected, theme }) => (selected ? theme.neutral1 : theme.white)};
  cursor: pointer;
  border-radius: 16px;
  outline: none;
  user-select: none;
  border: none;
  font-size: 24px;
  font-weight: 535;
  height: ${({ hideInput }) => (hideInput ? '2.8rem' : '2.4rem')};
  width: 100%;
  padding: 0 8px;
  justify-content: space-between;
  margin-left: ${({ hideInput }) => (hideInput ? '0' : '12px')};
  :focus,
  :hover {
    background-color: ${({ selected, theme }) => (selected ? theme.surface2 : darken(0.05, theme.accent1))};
  }
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  ${({ pointerEvents }) => pointerEvents && `pointer-events: none`}
`

const RowBetweenContainer = styled(RowBetween)`
  border-radius: 16px;
  padding: 12px;
  background-color: ${({ theme }) => theme.surface2};

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    flex-direction: column;
  }
`

const RowBetweenRewardToken = styled(RowBetween)`
  @media only screen and (max-width: ${SMALL_MEDIA_BREAKPOINT}) {
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }
`

const Label = styled.label`
  font-size: 14px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.accent1};
`

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border: 1px solid ${({ theme }) => theme.accent2};
  border-radius: 12px;
  background-color: ${({ theme }) => theme.surface1};
  color: ${({ theme }) => theme.accent1};
  transition: border-color 0.2s ease-in-out;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.accent1};
  }
`

const SuccessModal = styled.div`
  background: ${({ theme }) => theme.surface1};
  border-radius: 20px;
  padding: 24px;
  width: 100%;
  max-width: 500px;
`

const IconButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.accent1};
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${({ theme }) => theme.accent2};
  }
`

const LinkRow = styled(RowBetween)`
  background: ${({ theme }) => theme.surface2};
  border-radius: 12px;
  padding: 12px;
  margin: 8px 0;
`

const ExternalLink = styled.a`
  color: ${({ theme }) => theme.accent1};
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    text-decoration: underline;
  }
`

const CurrencyFarms = styled(CurrencyInputPanel)`
  z-index: 0;
`

export default SubmitFarm
