import clsx from 'clsx'
import { Box } from 'nft/components/Box'
import { ApprovedCheckmarkIcon } from 'nft/components/icons'
import React from 'react'

import * as styles from './Checkbox.css'

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  hovered: boolean
  children: React.ReactNode
}

export const Checkbox: React.FC<CheckboxProps> = ({ hovered, children, className, ...props }: CheckboxProps) => {
  const isRisky = className?.includes('risky-checkbox')

  return (
    <Box
      as="label"
      display="flex"
      alignItems="center"
      position="relative"
      overflow="hidden"
      cursor="pointer"
      lineHeight="1"
    >
      {children}
      <Box
        as="span"
        borderColor={
          isRisky
            ? props.checked || hovered
              ? 'white'
              : 'neutral2'
            : props.checked || hovered
            ? 'accent1_dark'
            : 'neutral2'
        }
        className={styles.checkbox}
        background={
          props.checked ? (isRisky ? 'deprecated_gold' : 'accent1_dark') : isRisky ? 'transparent' : 'transparent'
        }
        style={{
          boxShadow: isRisky
            ? `0 0 6px ${props.checked ? 'rgba(255, 102, 0, 0.4)' : 'rgba(255, 102, 0, 0.5)'}`
            : 'none',
        }}
        aria-hidden="true"
      />
      <input {...props} className={styles.input} type="checkbox" />
      <ApprovedCheckmarkIcon
        style={{
          filter: isRisky ? 'drop-shadow(0 0 2px rgba(255, 102, 0, 0.6))' : 'none',
          color: isRisky ? 'white' : undefined,
        }}
        className={clsx(styles.checkMark, props.checked && styles.checkMarkActive)}
      />
    </Box>
  )
}
