import { Trans } from '@lingui/macro'
import { Currency } from '@uniswap/sdk-core'
import { Text } from 'rebass'

import { ButtonPrimary } from '../../../components/Button'
import CurrencyLogo from '../../../components/Logo/CurrencyLogo'
import { RowBetween, RowFixed } from '../../../components/Row'
import { ThemedText } from '../../../theme'
import { FarmAction } from './details'

function actionRowText(farmAction?: FarmAction) {
  if (farmAction === FarmAction.Deposit) return 'Depositing'
  if (farmAction === FarmAction.Stake) return 'Staking'
  if (farmAction === FarmAction.Unstake) return 'Unstaking'
  if (farmAction === FarmAction.Withdraw) return 'Withdrawing'
  if (farmAction === FarmAction.Claim) return 'Claiming Rewards'
  if (farmAction === FarmAction.Create) return 'Creating Farm'
  return ''
}

function statusMessage(farmAction?: FarmAction) {
  if (farmAction === FarmAction.Deposit) return 'Confirm Deposit To Farm'
  if (farmAction === FarmAction.Stake) return 'Confirm Stake To Farm'
  if (farmAction === FarmAction.Unstake) return 'Confirm Unstake From Farm'
  if (farmAction === FarmAction.Withdraw) return 'Confirm Withdraw from Farm'
  if (farmAction === FarmAction.Claim) return 'Confirm claiming rewards Farm'
  if (farmAction === FarmAction.Create) return 'Confirm Create Farm'
  return ''
}

export function ConfirmAddFarmModalBottom({
  lpToken,
  quoteToken,
  formattedTokenName,
  onExecute,
  farmAction,
}: {
  lpToken: Currency
  quoteToken: Currency
  formattedTokenName: string
  onExecute: () => void
  farmAction?: FarmAction
}) {
  return (
    <>
      <RowBetween></RowBetween>
      <RowBetween>
        <ThemedText.DeprecatedBody>
          <Trans>
            {actionRowText(farmAction)} {formattedTokenName}
          </Trans>
        </ThemedText.DeprecatedBody>
        <RowFixed>
          <CurrencyLogo currency={lpToken} style={{ marginRight: '8px' }} />
          <CurrencyLogo currency={quoteToken} style={{ marginRight: '8px' }} />

          {/* <ThemedText.DeprecatedBody>{amountDelta?.toSignificant(6)}</ThemedText.DeprecatedBody> */}
        </RowFixed>
      </RowBetween>
      <ButtonPrimary style={{ margin: '20px 0 0 0' }} onClick={onExecute}>
        <Text fontWeight={535} fontSize={20}>
          <Trans>{statusMessage(farmAction)}</Trans>
        </Text>
      </ButtonPrimary>
    </>
  )
}
