import { DEFAULT_ERC20_DECIMALS } from 'constants/tokens'
import JSBI from 'jsbi'

export const DEFAULT_DECIMALS_EXPANDED = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(DEFAULT_ERC20_DECIMALS))

export function ToDecimalsExpandedOrDefault(decimals: number | undefined) {
  if (!decimals) return DEFAULT_DECIMALS_EXPANDED

  return ToDecimalsExpanded(decimals)
}

export function ToDecimalsExpanded(decimals: number) {
  const tokenDecimals = JSBI.BigInt(decimals)
  return JSBI.exponentiate(JSBI.BigInt(10), tokenDecimals)
}
