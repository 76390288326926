import { Trans } from '@lingui/macro'
import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { farmPricesAtom } from 'components/PriceFetchers/FarmPriceFetcherComponent'
import { MAX_WIDTH_MEDIA_BREAKPOINT } from 'components/Tokens/constants'
import { PAGE_SIZE } from 'graphql/data/TopTokens'
import { useGetLP5DayTradeFees } from 'hooks/v2/useGetLPTradeFees'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai/utils'
import JSBI from 'jsbi'
import { ReactNode, useMemo, useState } from 'react'
import { AlertTriangle } from 'react-feather'
import { useUSDPricesWithFallback } from 'state/cache/hooks'
import { useFetchFarmsUser } from 'state/farm/hooks'
import { Farm } from 'state/farm/types'
import styled from 'styled-components'

import { FarmSortMethod, sortAscendingAtom, sortMethodAtom } from '../state'
import { HeaderRow, LoadedRow, LoadingRow } from './FarmRow'

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  background-color: ${({ theme }) => theme.surface1};
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.surface3};
`

const FarmDataContainer = styled.div`
  & > div:nth-child(odd) {
    background: ${({ theme }) => theme.surface2};
  }

  & > div:nth-child(even) {
    background-color: transparent;
  }

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const NoFarmDisplay = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  color: ${({ theme }) => theme.neutral2};
  font-size: 16px;
  font-weight: 535;
  align-items: center;
  padding: 0px 28px;
  gap: 8px;
`

function NoFarmsState({ message }: { message: ReactNode }) {
  return (
    <GridContainer>
      <HeaderRow />
      <NoFarmDisplay>{message}</NoFarmDisplay>
    </GridContainer>
  )
}

const LoadingRows = ({ rowCount }: { rowCount: number }) => (
  <>
    {Array(rowCount)
      .fill(null)
      .map((_, index) => {
        return <LoadingRow key={index} first={index === 0} last={index === rowCount - 1} />
      })}
  </>
)

function LoadingFarmTable({ rowCount = PAGE_SIZE }: { rowCount?: number }) {
  return (
    <GridContainer>
      <HeaderRow />
      <FarmDataContainer>
        <LoadingRows rowCount={rowCount} />
      </FarmDataContainer>
    </GridContainer>
  )
}

// export function useStaticFarms(): UseFarmsReturnValue {
//   return useMemo(() => {
//     return {
//       farms: [
//         {
//           name: 'BTT SpookyTest Farm',
//           symbol: 'BTT-SPKT',
//           pid: 0,
//           version: 3,
//           lpAddress: '0x6bA70bF081aa208C6d9658E604506fb261544E23',
//           tokenA: '0x23181f21dea5936e24163ffaba4ea3b316b57f3c',
//           tokenB: '0x3850fa3db6e5acc6d06efc9b1eb4a9d1c0e3e068',
//           rewardsTokenAddresses: ['0x70e878aBf7985AE31453D3a914c855fE39120Cc4'],
//           tvl: 4000,
//           apy: 7.6,
//           price: 45,
//           deposited: 145,
//           isActive: true,
//         },
//         {
//           name: 'Bittorrent Dummy Farm',
//           symbol: 'WBTT-DUMMY',
//           pid: 1,
//           version: 3,
//           lpAddress: '0x87aB20CE4667Af3612BF8F75b97D1918a1ABE691',
//           tokenA: '0x23181f21dea5936e24163ffaba4ea3b316b57f3c',
//           tokenB: '0xe745071d92c6eddc552060f74aa5c8133830519a',
//           rewardsTokenAddresses: ['0x70e878aBf7985AE31453D3a914c855fE39120Cc4'],
//           tvl: 15000,
//           apy: 4.2,
//           price: 1.15,
//           deposited: 3265,
//           isActive: true,
//         },
//       ],
//       farmSortRank: {
//         ['0x6bA70bF081aa208C6d9658E604506fb261544E23']: 1,
//         ['0x87aB20CE4667Af3612BF8F75b97D1918a1ABE691']: 2,
//       },
//       loadingFarms: false,
//     }
//   }, [])
// }

function useSortedFarms(farms: Farm[]) {
  const sortMethod = useAtomValue(sortMethodAtom)
  const sortAscending = useAtomValue(sortAscendingAtom)

  return useMemo(() => {
    if (!farms) return undefined
    let farmsArray = Array.from(farms)
    switch (sortMethod) {
      // case FarmSortMethod.PRICE:
      //   tokenArray = tokenArray.sort((a, b) => (b?.market?.price?.value ?? 0) - (a?.market?.price?.value ?? 0))
      //   break
      // case FarmSortMethod.PERCENT_CHANGE:
      //   tokenArray = tokenArray.sort(
      //     (a, b) => (b?.market?.pricePercentChange?.value ?? 0) - (a?.market?.pricePercentChange?.value ?? 0)
      //   )
      //   break
      // case FarmSortMethod.TOTAL_VALUE_LOCKED:
      //   tokenArray = tokenArray.sort(
      //     (a, b) => (b?.market?.totalValueLocked?.value ?? 0) - (a?.market?.totalValueLocked?.value ?? 0)
      //   )
      //   break
      case FarmSortMethod.DEPOSITED:
        farmsArray = farmsArray.sort((a, b) => {
          const bStaked = b?.userData?.stakedBalance
          const aStaked = a?.userData?.stakedBalance
          if (!aStaked && !bStaked) return 0
          if (!aStaked) return -1
          if (!bStaked) return 1
          return aStaked.greaterThan(bStaked) ? 1 : -1
        })
        break
    }

    return sortAscending ? farmsArray.reverse() : farmsArray
  }, [farms, sortMethod, sortAscending])
}

export default function FarmTable() {
  // const { farms, farmSortRank, loadingFarms } = useStaticFarms()
  // const { isLoading, isError, fetchedFarmData } = useFetchFarms(FarmListJSON.farms)
  const isError = false
  const [fetchedFarmData] = useAtom(farmPricesAtom)
  const { isLoading: isLoadingUser, isError: isErrorUser, fetchedUserFarms } = useFetchFarmsUser(fetchedFarmData)
  const fetchedFarms = useMemo(() => {
    if (isLoadingUser || isErrorUser) return fetchedFarmData
    return fetchedUserFarms
  }, [fetchedFarmData, isLoadingUser, isErrorUser, fetchedUserFarms])

  const cachedTokenPrices = useUSDPricesWithFallback()
  const tradeFees = useGetLP5DayTradeFees(fetchedFarms)

  const { account, chainId } = useWeb3React()

  const [isInactive, setIsInactive] = useState(0)
  const { activeFarms, inactiveFarms } = useMemo(
    () =>
      fetchedFarms.reduce(
        (acc, farm) => {
          // Farm is active if it has any reward tokens with emission rate > 0
          const isActive = (farm?.rewardTokens ?? []).some((rewardToken) =>
            JSBI.greaterThan(JSBI.BigInt(rewardToken.rewardPerYear), JSBI.BigInt(0))
          )
          // Hide inactive non-prestaking farms if user has no staked balance
          if (!isActive && !account) return acc

          if (chainId !== ChainId.BIT_TORRENT_MAINNET) {
            if (isActive) acc.activeFarms.push(farm)
            else acc.inactiveFarms.push(farm)
          } else {
            if (isActive || !isActive) acc.activeFarms.push(farm)
            else acc.inactiveFarms.push(farm)
          }

          return acc
        },
        { activeFarms: [], inactiveFarms: [] } as { activeFarms: Farm[]; inactiveFarms: Farm[] }
      ),
    [account, chainId, fetchedFarms]
  )

  // TODO: toggle active/inactive farms
  // const toggleInactive = () => {
  //   setIsInactive((prevIsInactive) => (prevIsInactive === 0 ? 1 : 0));
  // };

  /* loading and error state */
  if (!fetchedFarms) {
    return <LoadingFarmTable rowCount={PAGE_SIZE} />
  } else if (isError) {
    return (
      <NoFarmsState
        message={
          <>
            <AlertTriangle size={16} />
            <Trans>An error occurred loading Farms. Please try again.</Trans>
          </>
        }
      />
    )
  } else if (fetchedFarms?.length === 0) {
    return <NoFarmsState message={<Trans>No Farms found</Trans>} />
  } else {
    return (
      <GridContainer>
        {/* <Button onClick={toggleInactive}>
          {isInactive ? <Trans>Show Active Farms</Trans> : <Trans>Show Inactive Farms</Trans>}
        </Button> */}
        <HeaderRow />
        <FarmDataContainer>
          {activeFarms.map(
            (farm, index) =>
              farm?.lpAddress && (
                <LoadedRow
                  key={farm.lpAddress}
                  farmListIndex={index}
                  farmListLength={fetchedFarms.length}
                  farm={farm}
                  priceMap={cachedTokenPrices}
                  averageDayTradeFeesMap={tradeFees}
                  // sortRank={farmSortRank[farm.lpAddress]}
                  // TODO: fix ranking
                  sortRank={1}
                />
              )
          )}
        </FarmDataContainer>
      </GridContainer>
    )
  }
}
