import { MaxUint256 } from '@uniswap/sdk-core'
import { BooLand, Spook } from 'abis/types'
import { BigNumber } from 'ethers/lib/ethers'
import JSBI from 'jsbi'

import { calculateGasMargin } from './calculateGasMargin'

const gasLimit = 350000

export const approve = async (lpContract: Spook | null, spender: BooLand | null, gasPrice: JSBI | undefined) => {
  if (!lpContract) {
    throw new Error('lpContract is null')
  }
  if (!spender) {
    throw new Error('spender is null')
  }

  const gasPriceBN = gasPrice ? BigNumber.from(gasPrice.toString()) : undefined
  const maxUint256BN = BigNumber.from(MaxUint256.toString())

  return lpContract.approve(spender.address, maxUint256BN, { gasPrice: gasPriceBN })
}

export const BooLandStake = async (BooLandContract: BooLand | null, amount: string | number) => {
  if (!BooLandContract) {
    throw new Error('BooLandContract is null')
  }

  const amountStr = amount.toString()
  const [integerPart, decimalPart = ''] = amountStr.split('.')
  const weiAmountStr = integerPart + decimalPart.padEnd(18, '0')
  const weiAmount = BigNumber.from(weiAmountStr)

  const estimatedGas = await BooLandContract.estimateGas.enter(weiAmount.toString())

  // Execute the transaction using the estimated gas limit
  const transaction = await BooLandContract.enter(weiAmount.toString(), {
    gasLimit: calculateGasMargin(estimatedGas),
  })

  return transaction
}

export const BooLandUnstake = async (BooLandContract: BooLand | null, amount: string | number) => {
  if (!BooLandContract) {
    throw new Error('BooLandContract is null')
  }
  const amountStr = amount.toString()
  const [integerPart, decimalPart = ''] = amountStr.split('.')
  const weiAmountStr = integerPart + decimalPart.padEnd(18, '0')
  const weiAmount = BigNumber.from(weiAmountStr)

  const estimatedGas = await BooLandContract.estimateGas.leave(weiAmount.toString())

  const bl = await BooLandContract.leave(weiAmount.toString(), { gasLimit: calculateGasMargin(estimatedGas) })

  return bl
}
