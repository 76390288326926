import { Trans } from '@lingui/macro'
import { Trace } from 'analytics'
import { ButtonPrimary } from 'components/Button'
import { AutoColumn } from 'components/Column'
import { RowBetween, RowFixed } from 'components/Row'
import { Dots } from 'components/swap/styled'
import {
  LARGE_MEDIA_BREAKPOINT,
  MAX_WIDTH_MEDIA_BREAKPOINT,
  MEDIUM_MEDIA_BREAKPOINT,
  MOBILE_MEDIA_BREAKPOINT,
} from 'components/Tokens/constants'
import { filterStringAtom } from 'components/Tokens/state'
import SearchBar from 'components/Tokens/TokenTable/SearchBar'
import TransactionConfirmationModal, { ConfirmationModalContent } from 'components/TransactionConfirmationModal'
import { useAllHarvest } from 'hooks/useHarvest'
import { useResetAtom } from 'jotai/utils'
import { ResponsiveButtonConfirmed } from 'pages/Pool/PositionPage'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Text } from 'rebass'
import { useIsTransactionPending } from 'state/transactions/hooks'
import styled, { useTheme } from 'styled-components'
import { ThemedText } from 'theme'

import FarmTable from './FarmTable/FarmTable'

const ExploreContainer = styled.div`
  width: 100%;
  min-width: 320px;
  padding: 68px 12px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    padding-top: 48px;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
  }
`

const HeaderContainer = styled.div`
  diplay: flex;
  position: relative;
  justify-content: center;
  max-height: 478px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  top: 40px;
  z-index: 1;
  max-width: ${LARGE_MEDIA_BREAKPOINT};
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const FiltersContainer = styled.div`
  display: flex;
  gap: 8px;
  height: 40px;

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    order: 2;
  }
`
const SearchContainer = styled(FiltersContainer)`
  width: 100%;

  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    margin: 0px;
    order: 1;
  }
`
const FiltersWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  margin-bottom: 0.2rem;
  margin-top: -40px;
  color: ${({ theme }) => theme.neutral3};
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 860px;
  position: relative;
  z-index: 2;
  @media only screen and (max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT}) {
    max-width: 1000px;
  }
  @media only screen and (max-width: ${LARGE_MEDIA_BREAKPOINT}) {
    max-width: 760px;
  }
  @media only screen and (max-width: ${MEDIUM_MEDIA_BREAKPOINT}) {
    max-width: 545px;
  }
  @media only screen and (max-width: ${MOBILE_MEDIA_BREAKPOINT}) {
    margin-top: 0px;
  }
`

const Farms = () => {
  const resetFilterString = useResetAtom(filterStringAtom)
  const { onReward } = useAllHarvest()
  const [txHash, setTxHash] = useState<string | undefined>()
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const theme = useTheme()
  const isClaimAllPending = useIsTransactionPending(txHash ?? undefined)

  const pendingText = <Trans>Claim All Farms</Trans>

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setTxHash(undefined)
  }, [txHash])

  const onClaimAll = useCallback(async () => {
    try {
      setAttemptingTxn(true)
      const response = await onReward()
      setTxHash(response.hash)
    } finally {
      setAttemptingTxn(false)
    }
  }, [onReward])

  function modalHeader() {
    return (
      <AutoColumn gap="sm" style={{ padding: '16px' }}>
        <RowBetween align="flex-end">
          <Text fontSize={16} fontWeight={535}>
            <Trans>Proceeding will claim all rewards from active farming</Trans>
          </Text>
          <RowFixed></RowFixed>
        </RowBetween>
        <ButtonPrimary mt="16px" onClick={onClaimAll} padding="10px">
          <Trans>Claim All</Trans>
        </ButtonPrimary>
      </AutoColumn>
    )
  }

  const location = useLocation()

  useEffect(() => {
    resetFilterString()
  }, [location, resetFilterString])

  return (
    <Trace page="farm-page" shouldLogImpression>
      <TransactionConfirmationModal
        isOpen={showConfirm}
        onDismiss={handleDismissConfirmation}
        attemptingTxn={attemptingTxn}
        hash={txHash ?? ''}
        reviewContent={() => (
          <ConfirmationModalContent
            title={<Trans>Claim All</Trans>}
            onDismiss={handleDismissConfirmation}
            topContent={modalHeader}
          />
        )}
        pendingText={pendingText}
      />
      <ExploreContainer>
        <div style={{ position: 'relative' }}>
          <HeaderContainer>
            <img src="https://assets.spooky.fi/farm.png"></img>
          </HeaderContainer>
          <FiltersWrapper>
            <SearchContainer>
              <SearchBar />
            </SearchContainer>
            <FiltersContainer>
              <ResponsiveButtonConfirmed
                data-testid="claim=all-fees-button"
                disabled={attemptingTxn || !!txHash}
                confirmed={!!txHash && !isClaimAllPending}
                width="50px"
                style={{ borderRadius: '12px' }}
                margin="1px"
                padding="4px 8px"
                onClick={() => setShowConfirm(true)}
              >
                {!!txHash && !isClaimAllPending ? (
                  <ThemedText.DeprecatedMain color={theme.neutral1}>
                    <Trans>Claimed</Trans>
                  </ThemedText.DeprecatedMain>
                ) : isClaimAllPending || attemptingTxn ? (
                  <ThemedText.DeprecatedMain color={theme.neutral1}>
                    {' '}
                    <Dots>
                      <Trans>Claiming</Trans>
                    </Dots>
                  </ThemedText.DeprecatedMain>
                ) : (
                  <>
                    <ThemedText.DeprecatedMain color={theme.white}>
                      <Trans>Claim All</Trans>
                    </ThemedText.DeprecatedMain>
                  </>
                )}
              </ResponsiveButtonConfirmed>
            </FiltersContainer>
          </FiltersWrapper>
        </div>
        <FarmTable />
      </ExploreContainer>
    </Trace>
  )
}

export default Farms
