import { TimePeriod } from 'graphql/data/util'
import { atomWithStorage } from 'jotai/utils'
import { useParams } from 'react-router-dom'

import FarmDetails from './details'

export const pageTimePeriodAtom = atomWithStorage<TimePeriod>('tokenDetailsTimePeriod', TimePeriod.DAY)

export default function FarmDetailsPage() {
  const { currencyIdA, currencyIdB, pid, version } = useParams<{
    currencyIdA: string
    currencyIdB: string
    pid: string
    version: string
  }>()
  return <FarmDetails currencyIdA={currencyIdA} currencyIdB={currencyIdB} pid={pid} version={version} />
}
