import { Trans } from '@lingui/macro'
import { ONE, ZERO } from '@uniswap/router-sdk'
import { CurrencyAmount, Fraction, Percent, Token } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import { useWeb3React } from '@web3-react/core'
// import { useStaticFarms } from 'pages/Farm/FarmTable/FarmTable'
import FarmListJSON from 'constants/farms/default_spooky_farms.json'
import { SUPPORTED_MASTER_CHEF_VERSIONS, ZERO_ADDRESS } from 'constants/misc'
import { DEFAULT_ERC20_DECIMALS, DEFAULT_REWARDS_TOKEN } from 'constants/tokens'
import { useFarmFetchHelper } from 'hooks/useContract'
import JSBI from 'jsbi'
import { useSingleCallResult, useSingleContractMultipleData } from 'lib/hooks/multicall'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { useMemo } from 'react'
import { ReactNode } from 'react'

import { Field } from '../burn/actions'
import { useTokenBalances } from '../connection/hooks'
import { EarningAmounts, Farm, FarmConfig, RewardToken } from './types'

const TWO = JSBI.BigInt(2)

//TODO: refactor this to use currency amounts instead
function parseEarnings(farmConfig: FarmConfig, chainId: number, fetchedFarmUserDataRes: any): EarningAmounts[] {
  const defaultRewardsToken = DEFAULT_REWARDS_TOKEN[chainId]
  return fetchedFarmUserDataRes.earnings
    .filter((x: any) => x.rewardToken != ZERO_ADDRESS)
    .map((earningsJsons: any) => {
      return {
        address: earningsJsons.rewardToken,
        earnings: JSBI.BigInt(earningsJsons.earnings),
      }
    })
}

//TODO: refactor this to use currency amounts instead
function parseRewardTokens(farmConfig: FarmConfig, chainId: number, fetchedFarmRes: any): RewardToken[] {
  const defaultRewardsToken = DEFAULT_REWARDS_TOKEN[chainId]

  return fetchedFarmRes.rewardTokens
    .map(({ rewardPerYear, allocPoint }: any, rewardIndex: number) => ({
      token: rewardIndex === 0 ? defaultRewardsToken : farmConfig.rewardTokenConfigs?.[rewardIndex - 1],
      allocPoint,
      rewardPerYear: JSBI.BigInt(rewardPerYear.toString()),
    }))
    .filter((x: RewardToken) => !!x.token)
}

export function useDerivedFarmLiquidityInfo(
  isRemove: boolean,
  isPercent: boolean,
  typedValue: string,
  pid?: string,
  version?: string,
  pair?: Pair | null
) {
  const { account } = useWeb3React()

  const relevantTokenBalances = useTokenBalances(account ?? undefined, [pair?.liquidityToken])
  const farmFetchHelper = useFarmFetchHelper()
  const isFetchUserBalanceValid = farmFetchHelper || account || pid !== undefined
  const stakedAmountBN: any | undefined = useSingleCallResult(
    isFetchUserBalanceValid ? farmFetchHelper : null,
    'fetchUserFarmData',
    [account, pid, version]
  ).result?.[0]

  const userLiquidity: undefined | CurrencyAmount<Token> = useMemo(() => {
    if (!pair) return

    if (!isRemove) {
      return relevantTokenBalances?.[pair.liquidityToken?.address ?? '']
    } else {
      return stakedAmountBN
        ? CurrencyAmount.fromRawAmount(pair.liquidityToken, JSBI.BigInt(stakedAmountBN.toString()))
        : undefined
    }
  }, [pair, isRemove, stakedAmountBN, relevantTokenBalances])

  let percentToRemove: Percent = new Percent('0', '100')
  // user specified a %
  if (isPercent) {
    percentToRemove = new Percent(typedValue, '100')
  } else if (pair?.liquidityToken) {
    const independentAmount = tryParseCurrencyAmount(typedValue, pair.liquidityToken)
    if (independentAmount && userLiquidity && !independentAmount.greaterThan(userLiquidity)) {
      percentToRemove = new Percent(independentAmount.quotient, userLiquidity.quotient)
    }
  }

  const parsedAmounts: {
    [Field.LIQUIDITY_PERCENT]: Percent
    [Field.LIQUIDITY]?: CurrencyAmount<Token>
  } = {
    [Field.LIQUIDITY_PERCENT]: percentToRemove,
    [Field.LIQUIDITY]:
      userLiquidity && percentToRemove && percentToRemove.greaterThan('0')
        ? CurrencyAmount.fromRawAmount(
            userLiquidity.currency,
            percentToRemove.multiply(userLiquidity.quotient).quotient
          )
        : undefined,
  }

  let error: ReactNode | undefined
  if (!account) {
    error = <Trans>Connect Wallet</Trans>
  }

  if (!parsedAmounts[Field.LIQUIDITY]) {
    error = error ?? <Trans>Enter an amount</Trans>
  }

  return { pair, parsedAmounts, error }
}

//TODO: this should really be useSingleActiveFarmFromPair
export const useMatchingFarmFromPairSingleOrDefault = (lpAddress: string | undefined): Farm | undefined => {
  const { chainId } = useWeb3React()
  // const farmResponse = useStaticFarms()
  // const fetchedFarms = useFetchFarms(FarmListJSON.farms)

  return useMemo(() => {
    // if (farmResponse.loadingFarms || !farmResponse.farms || !lpAddress) {
    //   return
    // }

    if (!lpAddress) return
    const filteredFarms = FarmListJSON.farms.filter(
      (f) =>
        f.chainId === chainId &&
        f.lpAddress.toLowerCase() == lpAddress.toLowerCase() &&
        //TODO: we need to support v2 for FTM
        SUPPORTED_MASTER_CHEF_VERSIONS.indexOf(f.version) !== -1
    )

    if (filteredFarms.length !== 1) {
      return
    }
    return filteredFarms[0]
  }, [/*farmResponse,*/ lpAddress, chainId])
}

//TODO: ideally the farms should be downloaded externall but for now I'll operate on them in code
export const useMatchingFarmFromPair = (
  lpAddress: string | undefined,
  version: string | undefined,
  pid: string | undefined
): Farm | undefined => {
  const { chainId } = useWeb3React()
  // const farmResponse = useStaticFarms()
  // const fetchedFarms = useFetchFarms(FarmListJSON.farms)

  return useMemo(() => {
    // if (farmResponse.loadingFarms || !farmResponse.farms || !lpAddress) {
    //   return
    // }

    if (!lpAddress) return
    if (version === undefined || isNaN(Number(version))) return
    if (pid === undefined || isNaN(Number(pid))) return

    return FarmListJSON.farms.filter(
      (f) =>
        f.chainId === chainId &&
        f.lpAddress.toLowerCase() == lpAddress.toLowerCase() &&
        //TODO: we need to support v2 for FTM
        f.version === Number(version) &&
        f.pid === Number(pid)
    )[0]
  }, [/*farmResponse,*/ lpAddress, chainId, pid, version])
}

export const useFetchFarmsUser = (
  farmConfigs: FarmConfig[]
): { isLoading: boolean; isError: boolean; fetchedUserFarms: Farm[] } => {
  const { account, chainId } = useWeb3React()
  const userChainId = account != undefined ? chainId : undefined
  const farmFetchHelper = useFarmFetchHelper()
  const farmsOnChain = useMemo(() => {
    return farmConfigs.filter((f) => userChainId == f.chainId)
  }, [farmConfigs, userChainId])

  const farmUserLpParams = farmsOnChain.map((farm) => [account, farm.lpAddress, farm.version])
  //TODO: I don't think well actually need this, this give us the allowance and tokenbalance for the user
  // We already have hooks to get the token balance and check the allowances
  const fetchFarmUserLPResults = useSingleContractMultipleData(farmFetchHelper, 'fetchUserLpData', farmUserLpParams)

  const loadingFarmUserLP = useMemo(
    () => fetchFarmUserLPResults.some(({ loading }) => loading),
    [fetchFarmUserLPResults]
  )
  const errorFarmUserLP = useMemo(() => fetchFarmUserLPResults.some(({ error }) => error), [fetchFarmUserLPResults])

  const farmUserParams = farmsOnChain.map((farm) => [account, farm.pid, farm.version])
  const fetchFarmUserDataResults = useSingleContractMultipleData(farmFetchHelper, 'fetchUserFarmData', farmUserParams)

  const loadingFarmUserData = useMemo(
    () => fetchFarmUserDataResults.some(({ loading }) => loading),
    [fetchFarmUserDataResults]
  )
  const errorFarmUserData = useMemo(
    () => fetchFarmUserDataResults.some(({ error }) => error),
    [fetchFarmUserDataResults]
  )

  const isLoading = loadingFarmUserData && loadingFarmUserLP
  const isError = errorFarmUserData && errorFarmUserLP
  //
  // userData?: {
  //     allowance: JSBI
  //     tokenBalance: JSBI
  //     stakedBalance: JSBI
  //     rewardTokenEarnings: JSBI[]
  // }
  const parsedUserFarmConfigs = useMemo(() => {
    return farmsOnChain.map((farmConfig, index) => {
      const parsedFarm: any = { ...farmConfig }
      if (!chainId) {
        return parsedFarm
      }
      // if the user data already exists no need to initialize it again
      parsedFarm['userData'] = parsedFarm['userData'] ?? {
        allowance: ZERO,
        tokenBalance: ZERO,
        stakedBalance: ZERO,
        rewardTokenEarnings: [],
      }
      if (!loadingFarmUserData && !errorFarmUserData) {
        const fetchedFarmUserDataRes: any = fetchFarmUserDataResults[index].result
        const lpToken = new Token(chainId, farmConfig.lpAddress, DEFAULT_ERC20_DECIMALS)
        parsedFarm.userData.stakedBalance = CurrencyAmount.fromRawAmount(lpToken, fetchedFarmUserDataRes.staked)
        parsedFarm.userData.rewardTokenEarnings = parseEarnings(farmConfig, chainId, fetchedFarmUserDataRes)
      }
      if (!loadingFarmUserLP && !errorFarmUserLP) {
        const fetchedFarmUserLPRes: any = fetchFarmUserLPResults[index].result
        parsedFarm.userData.allowance = fetchedFarmUserLPRes.allowance
        parsedFarm.userData.tokenBalance = fetchedFarmUserLPRes.balance
      }
      return parsedFarm
    })
  }, [
    chainId,
    farmsOnChain,
    loadingFarmUserData,
    errorFarmUserData,
    fetchFarmUserDataResults,
    loadingFarmUserLP,
    errorFarmUserLP,
    fetchFarmUserLPResults,
  ])

  return { isLoading, isError, fetchedUserFarms: parsedUserFarmConfigs }
}

export const useFetchFarms = (
  farmConfigs: FarmConfig[]
): { isLoading: boolean; isError: boolean; fetchedFarmData: Farm[] } => {
  const { chainId } = useWeb3React()
  const farmFetchHelper = useFarmFetchHelper()
  const farmsOnChain = useMemo(() => {
    //TODO: we need to support version 2 for FTM
    return farmConfigs.filter((f) => chainId == f.chainId && SUPPORTED_MASTER_CHEF_VERSIONS.indexOf(f.version) !== -1)
  }, [chainId, farmConfigs])

  const farmParams = farmsOnChain.map((farm) => [farm.pid, farm.version])
  const fetchFarmResults = useSingleContractMultipleData(farmFetchHelper, 'fetchFarmData', farmParams)

  const farmLPParams = farmsOnChain.map((farm) => [
    farm.lpAddress,
    farm.token.address,
    farm.quoteToken.address,
    farm.version,
  ])
  const fetchFarmLPResults = useSingleContractMultipleData(farmFetchHelper, 'fetchLpData', farmLPParams)

  const loadingFarm = useMemo(() => fetchFarmResults.some(({ loading }) => loading), [fetchFarmResults])
  const errorFarm = useMemo(() => fetchFarmResults.some(({ error }) => error), [fetchFarmResults])

  const loadingLP = useMemo(() => fetchFarmLPResults.some(({ loading }) => loading), [fetchFarmLPResults])
  const errorLP = useMemo(() => fetchFarmLPResults.some(({ error }) => error), [fetchFarmLPResults])

  const isLoading = loadingFarm && loadingLP
  const isError = errorFarm && errorLP
  const populatedFarmConfigs = useMemo(() => {
    return farmsOnChain.map((farmConfig, index) => {
      const parsedFarm: any = { ...farmConfig }
      if (!chainId) {
        return parsedFarm
      }
      if (!loadingFarm && !errorFarm) {
        const fetchedFarmRes: any = fetchFarmResults[index].result
        //TODO: refactor this to use currency amounts instead
        const rewardTokens = parseRewardTokens(farmConfig, chainId, fetchedFarmRes)
        const multiplier =
          rewardTokens.length > 0
            ? `${rewardTokens.reduce((acc: any, rT: any) => acc + rT.allocPoint, 0) / 100}X`
            : '0X'

        parsedFarm['multiplier'] = multiplier
        parsedFarm['rewardTokens'] = rewardTokens

        //... Parse fetchedFarmCall
      }
      if (!loadingLP && !errorLP) {
        const token = new Token(
          farmConfig.chainId,
          farmConfig.token.address,
          farmConfig.token.decimals,
          farmConfig.token.symbol
        )
        const quoteToken = new Token(
          farmConfig.chainId,
          farmConfig.quoteToken.address,
          farmConfig.quoteToken.decimals,
          farmConfig.quoteToken.symbol
        )

        const lpToken = new Token(chainId, farmConfig.lpAddress, DEFAULT_ERC20_DECIMALS, 'SLP')

        const fetchedFarmLPCall: any = fetchFarmLPResults[index].result
        const {
          lpBalanceInChef: lpBalanceInChefBN,
          lpSupply: lpSupplyBN,
          quoteBalanceInLp: quoteBalanceInLpBN,
          tokenBalanceInLp: tokenBalanceInLpBN,
        } = fetchedFarmLPCall

        const lpBalanceInChef = lpBalanceInChefBN.toString()
        const lpSupply = lpSupplyBN.toString()

        //This is the total quote balance of the entire LP not just for farms
        const quoteBalanceInLp: string = quoteBalanceInLpBN.toString()
        //This is the total token balance of the entire LP not just for farms
        const tokenBalanceInLp: string = tokenBalanceInLpBN.toString()

        // const quoteToken = new Token(chainId, farmConfig.quoteToken.address, farmConfig.quoteToken.decimals)
        // const quoteTokenAmountN = CurrencyAmount.fromRawAmount(quoteToken, JSBI.BigInt(quoteBalanceInLp))

        //TODO: this probably should be instanciated using CurrencyAmounts
        //TODO: understand the logic a bit better this was copied from the original project
        //NOTE: this logic should be checked again during the review as the logic is complicated

        //The Fetch contract obtains the token amount
        const lpBalanceInChefBI = JSBI.BigInt(lpBalanceInChef)
        const farmLPSupplyVsTotalLPSupplyRatio = new Fraction(lpBalanceInChefBI, JSBI.BigInt(lpSupply))
        // const normalizedLPBalance = new Fraction(JSBI.BigInt(lpSupply), DEFAULT_DECIMALS_EXPANDED)
        // const normalizedQuoteBalanceInLP = new Fraction(JSBI.BigInt(quoteBalanceInLp), DEFAULT_DECIMALS_EXPANDED)
        // const lpTotalInQuoteToken = normalizedQuoteBalanceInLP.multiply(TWO).multiply(farmLPSupplyVsTotalLPSupplyRatio)

        const tokenAmountInFarmFrac = new Fraction(JSBI.BigInt(tokenBalanceInLp), ONE).multiply(
          farmLPSupplyVsTotalLPSupplyRatio
        )
        const tokenAmountInFarm = CurrencyAmount.fromFractionalAmount(
          token,
          tokenAmountInFarmFrac.numerator,
          tokenAmountInFarmFrac.denominator
        )

        const quoteTokenAmountInFarmFrac = new Fraction(JSBI.BigInt(quoteBalanceInLp), ONE).multiply(
          farmLPSupplyVsTotalLPSupplyRatio
        )

        const quoteTokenAmountInFarm = CurrencyAmount.fromFractionalAmount(
          quoteToken,
          quoteTokenAmountInFarmFrac.numerator,
          quoteTokenAmountInFarmFrac.denominator
        )

        // const tokenPriceVsQuote = tokenAmount.equalTo(ZERO)
        //   ? new Fraction(ZERO, TWO)
        //   : quoteTokenAmount.divide(tokenAmount)

        // parsedFarm['tokenAmount'] = tokenAmount
        // parsedFarm['quoteTokenAmount'] = quoteTokenAmount
        // parsedFarm['lpTotalInQuoteToken'] = lpTotalInQuoteToken
        // parsedFarm['tokenPriceVsQuote'] = tokenPriceVsQuote
        parsedFarm['lpBalanceInChef'] = CurrencyAmount.fromRawAmount(lpToken, lpBalanceInChefBI)
        parsedFarm['lpTotalSupply'] = lpSupply
        parsedFarm['tokenReserve'] = tokenAmountInFarm
        parsedFarm['quoteTokenReserve'] = quoteTokenAmountInFarm
        //... Parse LP call
      }
      return parsedFarm
    })
  }, [loadingFarm, errorFarm, fetchFarmResults, loadingLP, errorLP, fetchFarmLPResults, chainId, farmsOnChain])

  return { isLoading, isError, fetchedFarmData: populatedFarmConfigs }
}
