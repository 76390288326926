import { Currency, CurrencyAmount, Fraction, Percent, Price, TradeType } from '@uniswap/sdk-core'
import { BIG_INT_ONE, BIG_INT_ZERO } from 'constants/misc'
import JSBI from 'jsbi'
import { IHasQuotePropertiesBase } from 'state/routing/types'
import invariant from 'tiny-invariant'

const ONE_FRACTION = new Fraction(1, 1)

export function calculateSlippageAmount(value: CurrencyAmount<Currency>, slippage: Percent): [JSBI, JSBI] {
  if (slippage.lessThan(0) || slippage.greaterThan(ONE_FRACTION)) throw new Error('Unexpected slippage')
  return [value.multiply(ONE_FRACTION.subtract(slippage)).quotient, value.multiply(ONE_FRACTION.add(slippage)).quotient]
}

// eslint-disable-next-line import/no-unused-modules
export function rateMinimumAmountOut(
  quote: { tradeType: TradeType },
  slippageTolerance: Percent,
  amountOut: CurrencyAmount<Currency>
): CurrencyAmount<Currency> {
  invariant(!slippageTolerance.lessThan(BIG_INT_ZERO), 'SLIPPAGE_TOLERANCE')
  if (quote.tradeType === TradeType.EXACT_OUTPUT) {
    return amountOut
  } else {
    const slippageAdjustedAmountOut = new Fraction(BIG_INT_ONE)
      .add(slippageTolerance)
      .invert()
      .multiply(amountOut.quotient).quotient
    return CurrencyAmount.fromRawAmount(amountOut.currency, slippageAdjustedAmountOut)
  }
}

/**
 * Get the maximum amount in that can be spent via this trade for the given slippage tolerance
 * @param slippageTolerance The tolerance of unfavorable slippage from the execution price of this trade
 * @returns The amount in
 */
// eslint-disable-next-line import/no-unused-modules
export function rateMaximumAmountIn(
  quote: IHasQuotePropertiesBase,
  slippageTolerance: Percent,
  amountIn: CurrencyAmount<Currency>
): CurrencyAmount<Currency> {
  invariant(!slippageTolerance.lessThan(BIG_INT_ZERO), 'SLIPPAGE_TOLERANCE')
  if (quote.tradeType === TradeType.EXACT_INPUT) {
    return amountIn
  } else {
    const slippageAdjustedAmountIn = new Fraction(BIG_INT_ONE)
      .add(slippageTolerance)
      .multiply(amountIn.quotient).quotient
    return CurrencyAmount.fromRawAmount(amountIn.currency, slippageAdjustedAmountIn)
  }
}

// TODO Remove this ignore once you reload the project
// eslint-disable-next-line import/no-unused-modules
export function worstExecutionPrice(
  quote: IHasQuotePropertiesBase,
  slippageTolerance: Percent
): Price<Currency, Currency> {
  return new Price(
    quote.inputAmount.currency,
    quote.outputAmount.currency,
    rateMaximumAmountIn(quote, slippageTolerance, quote.inputAmount).quotient,
    rateMinimumAmountOut(quote, slippageTolerance, quote.outputAmount).quotient
  )
}
