// Type assertion to inform TypeScript about the request method
const ethereum = window.ethereum as any

export const registerToken = async (
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimals: number,
  tokenImage?: string
) => {
  if (ethereum && ethereum.request) {
    try {
      const tokenAdded = await ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenSymbol.split('(')[0],
            decimals: tokenDecimals,
            image: tokenImage || `https://www.spooky.fi/assets/tokens/${tokenSymbol}.png`,
          },
        },
      })

      return tokenAdded
    } catch (error) {
      console.error('Error adding token', error)
      return false
    }
  } else {
    console.error('window.ethereum is not defined or does not support request method')
    return false
  }
}
