interface CustomSpinnerGrimAnimationProps {
  size?: string
}

export default function CustomSpinnerGrimAnimation({ size = '190px' }: CustomSpinnerGrimAnimationProps) {
  return (
    <video autoPlay loop muted width={size}>
      <source src="https://assets.spooky.fi/grimSpinner.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  )
}
